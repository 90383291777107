import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { VideoPlayer } from './components/VideoPlayer';
import { useSocketContext } from './SocketContext';
import { Messages } from '../Messages/Messages';
import { PureToneAudiometry } from './exercises/PureToneAudiometry/PureToneAudiometry';
import { PeripheralsManagementHeader } from './exercises/PureToneAudiometry/PeripheralsManagementHeader';

export const Teleconsultation = ({ consultation }) => {
  const { setName, stream, callUser, leaveCall } = useSocketContext();
  const [earSide, setEarSide] = useState('leftEar');
  const [pickedPeripheral, setPickedPeripheral] = useState('earphones');
  const [hearingAid, setHearingAid] = useState(false);

  useEffect(() => {
    if (consultation) {
      setName(
        `${consultation.doctor.first_name} ${consultation.doctor.last_name}`
      );
    }
  }, [consultation]);

  useEffect(() => {
    if (stream) {
      callUser(consultation.specialist._id, consultation);
    }
  }, [stream]);

  useEffect(
    () => async () => {
      await leaveCall('userDisconnected');
    },
    []
  );

  return (
    <>
      <Row>
        <Col span={7}>
          <VideoPlayer />
        </Col>
        <Col span={17}>
          <PeripheralsManagementHeader
            setEarSide={setEarSide}
            earSide={earSide}
            setPickedPeripheral={setPickedPeripheral}
            pickedPeripheral={pickedPeripheral}
            hearingAid={hearingAid}
            setHearingAid={setHearingAid}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PureToneAudiometry />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Messages consultation={consultation} />
        </Col>
      </Row>
    </>
  );
};

Teleconsultation.propTypes = {
  consultation: PropTypes.shape({
    doctor: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    specialist: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired
};
