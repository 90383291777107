import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListPatients from './ListPatients';
import CreateUpdatePatient from './CreateUpdatePatient';
import ShowPatient from './ShowPatient/ShowPatient';

export const PatientRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdatePatient purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdatePatient purpose="edit" />} />
    <Route path="/show/:id" element={<ShowPatient />} />
    <Route index element={<ListPatients />} />
  </Routes>
);
