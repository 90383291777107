import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Card } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        navigate(-1);
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', { url: `${baseUrl}/${id}` });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      if (purpose === 'edit' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        <ContentCustom>
          <Card>
            <Spin spinning={isLoading || isParentLoading}>
              <Form
                ref={ref}
                {...formItemLayout}
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
              >
                {fields.map((field) =>
                  generateFields(tradKey || resource, field)
                )}
                {formExtra}
                <Form.Item {...tailFormItemLayout}>
                  <Row justify="end">
                    <Button
                      style={{ margin: '0 10px' }}
                      type="link"
                      danger
                      onClick={() => navigate(-1)}
                    >
                      {`${t('buttons.cancel')} `}
                      <CloseOutlined />
                    </Button>
                    <Button type="add" htmlType="submit" loading={isSubmitting}>
                      {`${t(submitLabel || 'buttons.save')} `}
                      <CheckOutlined />
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false
};
