import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileOutlined,
  InboxOutlined,
  PhoneOutlined,
  TeamOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useSocketContext } from '../../VideoConferencing/SocketContext';
import { useDownloadDocument } from '../../../../utils/downloadDoc';

export const ExtraPageHeaderCustomButtons = ({
  consultation,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const {
    callAccepted,
    callEnded,
    leaveCall,
    screen,
    setScreen,
    materialOK,
    openMotiveModal,
    setOpenMotiveModal
  } = useSocketContext();
  const { dispatchAPI } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  const updateConsultationStatus = async (status) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `consultations/${consultation._id}/${status}`
      });
      setScreen(0);
    } catch (e) {
      message(e);
    }
  };

  const closeConsultation = async () => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `consultations/${consultation._id}/close-consultation`,
        body: {
          status: 'DONE'
        }
      });
      navigate('/consultations');
    } catch (e) {
      message(e);
    }
  };

  const downloadDocuments = () => {
    consultation.documents.forEach((document) => downloadDocument(document));
  };

  const isInTimeRange = consultation.date
    ? moment(consultation.date).subtract(1, 'hours').isBefore(moment())
    : false;

  const accessWaitingRoomGranted =
    !callAccepted && consultation.status === 'PLANNED' && isInTimeRange;

  const shouldDisplayButton =
    consultation.anamnese &&
    consultation.otoscopy &&
    consultation.audiogram_actions &&
    consultation.prescription;
  return (
    <>
      {screen === 0 && (
        <Row gutter={[8, 8]} style={{ gap: 8 }}>
          {accessWaitingRoomGranted && (
            <>
              <Button type="primary" onClick={() => setScreen(1)}>
                <Space>
                  <TeamOutlined />
                  {`${t('consultations.buttons.join_waiting_room')} `}
                </Space>
              </Button>
              <Link
                to={{
                  pathname: `${routes.PATIENTS}/show/${consultation?.patient?._id}`
                }}
              >
                <Button type="primary">
                  <Space>
                    <FileOutlined />
                    {t('consultations.buttons.access_patient')}
                  </Space>
                </Button>
              </Link>
            </>
          )}
          {consultation.status === 'DONE' && (
            <Button type="primary" onClick={downloadDocuments}>
              <Space>
                <DownloadOutlined />
                {`${t('consultations.buttons.download_documents')} `}
              </Space>
            </Button>
          )}
          {consultation.status === 'PLANNED' && (
            <Button danger onClick={() => setOpenMotiveModal(!openMotiveModal)}>
              <Space>
                <CloseOutlined />
                {t('consultations.buttons.cancel_consultation')}
              </Space>
            </Button>
          )}
          {consultation.status === 'DONE' && (
            <Popconfirm
              title={t('consultations.show.archive.title')}
              okText={t('consultations.show.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('consultations.show.archive.cancel')}
              onConfirm={() => updateConsultationStatus('ARCHIVED')}
              icon={<WarningOutlined />}
            >
              <Button danger>
                <Space>
                  <InboxOutlined />
                  {t('consultations.buttons.archive_consultation')}
                </Space>
              </Button>
            </Popconfirm>
          )}
          {shouldDisplayButton && consultation.status === 'PLANNED' && (
            <Popconfirm
              title={t('consultations.show.close.title')}
              okText={t('consultations.show.close.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('consultations.show.close.cancel')}
              onConfirm={closeConsultation}
              icon={<WarningOutlined />}
            >
              <Button danger>
                <Space>
                  <InboxOutlined />
                  {t('consultations.buttons.close_consultation')}
                </Space>
              </Button>
            </Popconfirm>
          )}
        </Row>
      )}
      {[1, 3].includes(screen) && (
        <Row gutter={[8, 8]} style={{ gap: 8 }}>
          {materialOK && screen !== 3 && (
            <Button onClick={() => setScreen(2)}>
              <Space>
                <PhoneOutlined />
                {t('consultations.buttons.create_call')}
              </Space>
            </Button>
          )}
          <Button type="primary" onClick={() => setScreen(0)}>
            <Space>
              <EyeOutlined />
              {`${t('consultations.buttons.show_details')} `}
            </Space>
          </Button>
          <Link
            to={{
              pathname: `${routes.PATIENTS}/show/${consultation?.patient?._id}`
            }}
          >
            <Button type="primary">
              <Space>
                <FileOutlined />
                {t('consultations.buttons.access_patient')}
              </Space>
            </Button>
          </Link>
          {consultation.status === 'PLANNED' && (
            <Popconfirm
              title={t('consultations.files.delete.title')}
              okText={t('consultations.files.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('consultations.files.delete.cancel')}
              onConfirm={updateConsultationStatus}
              icon={<WarningOutlined />}
            >
              <Button danger>
                <Space>
                  <CloseOutlined />
                  {t('consultations.buttons.cancel_consultation')}
                </Space>
              </Button>
            </Popconfirm>
          )}
        </Row>
      )}
      {screen === 2 && callAccepted && !callEnded && (
        <Button onClick={() => leaveCall('callEnded')}>
          <Space>
            <PhoneOutlined />
            {t('consultations.buttons.end_consultation')}
          </Space>
        </Button>
      )}
    </>
  );
};

ExtraPageHeaderCustomButtons.propTypes = {
  consultation: {
    _id: PropTypes.string
  },
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.bool
};

ExtraPageHeaderCustomButtons.defaultProps = {
  consultation: null,
  forceRefresh: undefined,
  setForceRefresh: null
};
