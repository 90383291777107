import { Button, Form, Modal, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useSocketContext } from '../../VideoConferencing/SocketContext';

export const CancelMotiveModal = ({ id, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { openMotiveModal, setOpenMotiveModal } = useSocketContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();

  const [motives, setMotives] = useState([]);

  const handleCloseModal = () => {
    setOpenMotiveModal(false);
  };

  const cancelConsultation = async (values) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `consultations/${id}`,
        body: {
          status: 'CANCELLED',
          cancel_motive: values.cancel_motive
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const getMotives = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'cancel-motives?display=true'
      });
      setMotives(data);
    } catch (e) {
      message(e);
    }
  };

  const onFinish = async (values) => {
    await cancelConsultation(values);
    handleCloseModal();
    form.resetFields();
  };

  useEffect(() => {
    (async () => {
      await getMotives();
    })();
  }, []);

  return (
    <Modal
      onOk={handleCloseModal}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      maskClosable={false}
      width="80%"
      open={openMotiveModal}
      footer={null}
      className="consultation-creation-modal"
      title={t('consultations.modal.cancel_motive')}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={['cancel_motive']}
          style={{ display: 'flex', justifyContent: 'center' }}
          label={t('consultations.modal.motive')}
          rules={[{ required: true }]}
        >
          <Select style={{ width: '400px' }}>
            {motives.map(({ title, _id }) => (
              <Select.Option key={_id} value={_id}>
                {title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item />

        <Row justify="end">
          <Space>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={handleCloseModal}
            >
              <CloseOutlined />
              {`${t('buttons.cancel')} `}
            </Button>
          </Space>

          <Space>
            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
              <CheckOutlined />
              {t('buttons.confirm')}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

CancelMotiveModal.propTypes = {
  id: PropTypes.string,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

CancelMotiveModal.defaultProps = {
  id: undefined,
  forceRefresh: undefined,
  setForceRefresh: null
};
