import { Button, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useDocuments } from './useDocuments';

const iconSize = 18;

export const FilesManagement = ({ consultation }) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  const documents = consultation && useDocuments(consultation, t);

  return (
    <Card title={t('consultations.files.title')}>
      <Row>
        <Col span={24} className="custom-document-list">
          {documents?.map((document) => (
            <Row key={document._id} className="custom-list-item">
              <Row className="document-meta">
                <Col className="custom-list-label">
                  {!document?.file ? (
                    <CloseCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )}
                  {document?.title}
                </Col>
                <Col className="customfile">
                  {document?.file?.metadata?.originalName}
                </Col>
              </Row>

              {document?.file && (
                <Col className="custom-list-action">
                  <Button
                    onClick={() => viewDocument(document.file, 'show')}
                    type="link"
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Button>
                  <Button
                    onClick={() => downloadDocument(document.file)}
                    type="link"
                  >
                    <DownloadOutlined style={{ fontSize: iconSize }} />
                  </Button>
                </Col>
              )}
            </Row>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

FilesManagement.propTypes = {
  consultation: {
    anamnese: PropTypes.string,
    report: PropTypes.string
  }
};

FilesManagement.defaultProps = {
  consultation: null
};
