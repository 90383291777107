import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListPrescriptions from './ListPrescriptions';
import CreateUpdatePrescription from './CreateUpdatePrescription';
import ShowPrescription from './ShowPrescription';

export const PrescriptionRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePrescription purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePrescription purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPrescription />} />
    <Route index element={<ListPrescriptions />} />
  </Routes>
);
