import { Card, Col, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ContentCustom, DescriptionList } from '../../../../components';
import { listContentConsultation, listContentPatient } from '../../listContent';
import { FilesManagement } from './FilesManagement';
import { CallEvaluationPopup } from '../../VideoConferencing/components/CallEvaluationPopup';

export const ConsultationsDetails = ({
  consultation,
  isLoading,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ContentCustom>
        <Row>
          <Card title={t('consultations.show.consultation_details')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContentConsultation(consultation || {}, t)}
                translate
              />
            </Skeleton>
          </Card>
          <Card title={t('consultations.show.patient_infos')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContentPatient(consultation.patient || {}, t)}
                translate
              />
            </Skeleton>
          </Card>
          <Col span="24">
            <FilesManagement
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              consultation={consultation}
            />
          </Col>
        </Row>
      </ContentCustom>
      {!consultation.doctor_call_mark && (
        <CallEvaluationPopup id={consultation._id} />
      )}
    </>
  );
};

ConsultationsDetails.propTypes = {
  consultation: {
    anamnese: PropTypes.string,
    report: PropTypes.string
  },
  isLoading: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

ConsultationsDetails.defaultProps = {
  consultation: null,
  isLoading: false,
  forceRefresh: undefined,
  setForceRefresh: null
};
