import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const patientList = (data = {}) => {
  const {
    gender,
    first_name,
    last_name,
    email,
    date_of_birth,
    phone_number,
    patient_security_number,
    center,
    already_equipped
  } = data;
  const { t } = useTranslation();

  const formattedNumSecu = patient_security_number
    ? `${patient_security_number.slice(0, 1)} ${patient_security_number.slice(
        1,
        3
      )} ${patient_security_number.slice(3, 5)} ${patient_security_number.slice(
        5,
        7
      )} ${patient_security_number.slice(
        7,
        10
      )} ${patient_security_number.slice(
        10,
        13
      )} ${patient_security_number.slice(13, 15)}`
    : '-';

  return [
    {
      label: 'patients.show.gender',
      span: 1,
      content: t(`patients.genders.${gender}`) || t('errors.form.not_specified')
    },
    {
      label: 'patients.show.first_name',
      span: 1,
      content: first_name || t('errors.form.not_specified')
    },
    {
      label: 'patients.show.last_name',
      span: 1,
      content: last_name || t('errors.form.not_specified')
    },
    {
      label: 'patients.show.patient_security_number',
      span: 1,
      content: formattedNumSecu || t('errors.form.not_specified')
    },
    {
      label: 'patients.show.email',
      span: 1,
      content:
        <a href={`mailto:${email}`}>{email}</a> ||
        t('errors.form.not_specified')
    },
    {
      label: 'patients.show.phone_number_full',
      span: 1,
      content:
        phone_number && phone_number.number ? (
          <div>
            {` ${
              phone_number.country_code || '+33'
            } ${phone_number.number.slice(0, 1)} ${phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          t('errors.form.not_specified')
        )
    },
    {
      label: 'patients.show.date_of_birth',
      span: 1,
      content: date_of_birth
        ? moment(date_of_birth).format('ll')
        : t('errors.form.not_specified')
    },
    {
      label: 'patients.show.already_equipped',
      span: 1,
      content: already_equipped
        ? 'OUI'
        : 'NON' || t('errors.form.not_specified')
    },
    {
      label: 'patients.show.center',
      span: 1,
      content: (center && center.legal_name) || t('errors.form.not_specified')
    }
  ];
};

export default patientList;
