import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import { useHandleResize } from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logoEden.svg';

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row justify="center">
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
                {width > 768 && (
                  <img
                    style={{
                      width: 160,
                      marginBottom: 50
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )}
                <Title className="login-title">
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
