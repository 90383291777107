import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import {
  chartOptions,
  handleMax,
  handleMin,
  hoverBackgroundColors,
  symbolsDisplay
} from './utils/graphUtils';
import { DbAudioOutput } from './utils/DbAudioOutput';

export const PureToneAudiometryGraph = ({
  frequencyLevels,
  frequencyLevelsIndex,
  setFrequencyLevelsIndex,
  sensitivityLevels,
  sensitivityLevelsIndex,
  setSensitivityLevelsIndex,
  chartData,
  setChartData,
  activePointIndex,
  setActivePointIndex,
  saveOtoscopy,
  audioToPlay,
  dbLevel,
  mainPointCoordinates,
  setMainPointCoordinates,
  activeDatasetIndex,
  pickedExercise
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chartDataCopy = { ...chartData };
    const chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [
          {
            // Main point dataset
            ...chartDataCopy.datasets[0]
          },
          {
            // Left Ear Earphones scatter dataset
            ...chartDataCopy.datasets[1]
          },
          {
            // Left Ear Earphones line dataset
            data: chartData.datasets[1].data,
            backgroundColor: 'transparent',
            borderColor: 'red',
            borderWidth: 2,
            type: 'line'
          },
          {
            // Right Ear Earphones scatter dataset
            ...chartDataCopy.datasets[2]
          },
          {
            // Right Ear Earphones line dataset
            data: chartData.datasets[2].data,
            backgroundColor: 'transparent',
            borderColor: 'blue',
            borderWidth: 2,
            type: 'line'
          },
          {
            // Left Ear Transducer scatter dataset
            ...chartDataCopy.datasets[3]
          },
          {
            // Left Ear Transducer line dataset
            data: chartData.datasets[3].data,
            backgroundColor: 'transparent',
            borderColor: 'red',
            borderWidth: 2,
            type: 'line'
          },
          {
            // Right Ear Transducer scatter dataset
            ...chartDataCopy.datasets[4]
          },
          {
            // Right Ear Transducer line dataset
            data: chartData.datasets[4].data,
            backgroundColor: 'transparent',
            borderColor: 'blue',
            borderWidth: 2,
            type: 'line'
          },
          {
            // AudioField without hearingAid scatter dataset
            ...chartDataCopy.datasets[5]
          },
          {
            // AudioField without hearingAid line dataset
            data: chartData.datasets[5].data,
            backgroundColor: 'transparent',
            borderColor: 'black',
            borderWidth: 2,
            type: 'line'
          },
          {
            // AudioField with hearingAid scatter dataset
            ...chartDataCopy.datasets[6]
          },
          {
            // AudioField with hearingAid line dataset
            data: chartData.datasets[6].data,
            backgroundColor: 'transparent',
            borderColor: 'black',
            borderWidth: 2,
            type: 'line'
          },
          {
            // RightEar UCL scatter dataset
            ...chartDataCopy.datasets[7]
          },
          {
            // RightEar UCL line dataset
            data: chartData.datasets[7].data,
            backgroundColor: 'transparent',
            borderColor: 'black',
            borderWidth: 2,
            type: 'line'
          },
          {
            // LeftEar UCL scatter dataset
            ...chartDataCopy.datasets[8]
          },
          {
            // LeftEar UCL line dataset
            data: chartData.datasets[8].data,
            backgroundColor: 'transparent',
            borderColor: 'black',
            borderWidth: 2,
            type: 'line'
          }
        ]
      },
      options: chartOptions,
      plugins: [hoverBackgroundColors, symbolsDisplay]
    });
    chartRef.current.chartInstance = chart;

    const handleKeyDown = (event) => {
      let newPoint;

      switch (event.code) {
        case 'ArrowUp':
          newPoint = {
            x: mainPointCoordinates.x,
            y: handleMin(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              setMainPointCoordinates,
              'main'
            )
          };
          break;
        case 'ArrowDown':
          newPoint = {
            x: mainPointCoordinates.x,
            y: handleMax(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              setMainPointCoordinates,
              'main'
            )
          };
          break;
        case 'ArrowLeft':
          newPoint = {
            x: handleMin(
              frequencyLevels,
              frequencyLevelsIndex,
              'frequency',
              setFrequencyLevelsIndex,
              setMainPointCoordinates,
              'main',
              pickedExercise
            ),
            y: mainPointCoordinates.y
          };
          break;
        case 'ArrowRight':
          newPoint = {
            x: handleMax(
              frequencyLevels,
              frequencyLevelsIndex,
              'frequency',
              setFrequencyLevelsIndex,
              setMainPointCoordinates,
              'main',
              pickedExercise
            ),
            y: mainPointCoordinates.y
          };
          break;
        case 'Enter':
          newPoint = {
            x: mainPointCoordinates.x,
            y: mainPointCoordinates.y
          };
          setChartData((prevChartData) => {
            const updatedDatasets = prevChartData.datasets.map(
              (dataset, index) => {
                if (index === 0) {
                  return {
                    ...dataset,
                    data: [newPoint] // Set the data to contain only the newPoint
                  };
                }
                if (index === activeDatasetIndex) {
                  return {
                    ...dataset,
                    data: [...dataset.data, newPoint] // Add the newPoint to the active dataset
                  };
                }
                return dataset;
              }
            );
            return {
              ...prevChartData,
              datasets: updatedDatasets
            };
          });

          return setActivePointIndex(activePointIndex + 1);

        case 'KeyP':
          return DbAudioOutput(dbLevel, audioToPlay);
        default:
          return null;
      }
      return setChartData((prevChartData) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: [newPoint]
          },
          ...prevChartData.datasets.slice(1)
        ]
      }));
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      chart.destroy();
    };
  }, [chartData, chartOptions, activePointIndex]);

  return (
    <Row style={{ flexDirection: 'column' }}>
      <Col>
        <canvas ref={chartRef} />
      </Col>
      <Button onClick={saveOtoscopy}>SAVE</Button>
    </Row>
  );
};

PureToneAudiometryGraph.propTypes = {
  sensitivityLevels: PropTypes.arrayOf(PropTypes.number),
  frequencyLevels: PropTypes.arrayOf(PropTypes.shape({})),
  sensitivityLevelsIndex: PropTypes.number,
  setSensitivityLevelsIndex: PropTypes.func,
  chartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  setChartData: PropTypes.func,
  frequencyLevelsIndex: PropTypes.number,
  setFrequencyLevelsIndex: PropTypes.func,
  activePointIndex: PropTypes.number,
  setActivePointIndex: PropTypes.func,
  saveOtoscopy: PropTypes.func,
  audioToPlay: PropTypes.shape({
    play: PropTypes.func
  }),
  dbLevel: PropTypes.number.isRequired,
  mainPointCoordinates: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  setMainPointCoordinates: PropTypes.func,
  activeDatasetIndex: PropTypes.number,
  pickedExercise: PropTypes.string
};

PureToneAudiometryGraph.defaultProps = {
  sensitivityLevels: undefined,
  frequencyLevels: undefined,
  sensitivityLevelsIndex: 10,
  setSensitivityLevelsIndex: null,
  chartData: null,
  setChartData: null,
  frequencyLevelsIndex: undefined,
  setFrequencyLevelsIndex: null,
  activePointIndex: undefined,
  setActivePointIndex: null,
  saveOtoscopy: null,
  audioToPlay: null,
  mainPointCoordinates: { x: 60, y: 60 },
  setMainPointCoordinates: null,
  activeDatasetIndex: 1,
  pickedExercise: 'threshold'
};
