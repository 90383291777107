/* eslint-disable jsx-a11y/media-has-caption */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSocketContext } from '../SocketContext';

export const VideoPlayer = () => {
  const { t } = useTranslation();
  const { callAccepted, myVideo, userVideo, callEnded, stream, setStream } =
    useSocketContext();

  useEffect(() => {
    (async () => {
      const currentStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      setStream(currentStream);
      myVideo.current.srcObject = currentStream;
    })();
  }, []);

  return (
    <>
      <h1>{t('consultations.form.title.teleconsultation')}</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative'
        }}
      >
        {stream && (
          <video
            playsInline
            muted
            ref={myVideo}
            autoPlay
            className="user-video-player"
            style={{
              width: callAccepted && !callEnded ? '30%' : '100%',
              position: 'absolute',
              border: callAccepted && !callEnded ? '1px red solid' : 0,
              zIndex: 1
            }}
          />
        )}
        {callAccepted && !callEnded && (
          <video
            playsInline
            ref={userVideo}
            autoPlay
            className="user-video-player"
            style={{ width: '100%', position: 'absolute', zIndex: 0 }}
          />
        )}
      </div>
    </>
  );
};
