import { ListResource } from '../../components';
import { useColumns } from './columns';

const headers = [
  {
    label: 'hour_range',
    key: 'hour_range'
  },
  {
    label: 'date',
    key: 'date'
  }
];

export const Unavailabilities = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="unavailabilities"
      columns={columns}
      headers={headers}
      resourceModelName="Unavailability"
      extraQuery="!recurrence_start"
      withoutSearchBar
    />
  );
};
