import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { ExtraSubHeader } from './ExtraSubHeader';
import { ConsultationManagementPopover } from '../../components/Popover/ConsultationManagementPopover';
import { useSocketContext } from './VideoConferencing/SocketContext';

const ListConsultations = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [rangeDates, setRangeDates] = useState();
  const [switchStatus, setSwitchStatus] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const { materialOK } = useSocketContext();

  const willShowAll = showAll
    ? '&status=PLANNED,DONE,DELAYED'
    : '&status=PLANNED';

  const rangeDatesFilter = () =>
    `&date>=${moment(rangeDates[0])
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss[Z]')}&date<=${moment(rangeDates[1])
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss[Z]')}`;

  const extraQuery = `${switchStatus ? willShowAll : '&status=CANCELLED'}${
    rangeDates ? rangeDatesFilter() : ''
  }&populate=patient,doctor,specialist`;

  return (
    <ListResource
      resourceName="consultations"
      columns={columns}
      editAction={false}
      deleteAction={false}
      extraHeader={
        !materialOK && (
          <Button type="danger" onClick={() => navigate(`/material-test`)}>
            <CheckOutlined />
            {t('consultations.show.test_material')}
          </Button>
        )
      }
      extraSubHeader={
        <ExtraSubHeader
          setRangeDate={setRangeDates}
          switchStatus={switchStatus}
          setSwitchStatus={setSwitchStatus}
          showAll={showAll}
          setShowAll={setShowAll}
        />
      }
      uploadButtonSubstitutionMenu={
        <ConsultationManagementPopover location="consultations" />
      }
      withUploadButton={false}
      headers={false}
      withCreateButton={false}
      resourceModelName="Consultation"
      extraQuery={extraQuery}
      tradKey="consultations"
    />
  );
};

export default ListConsultations;
