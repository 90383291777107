import { createContext, useState, useRef, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const SocketContext = createContext();

export const SocketContextProvider = ({ children }) => {
  const { token, user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [stream, setStream] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState('');
  const [screen, setScreen] = useState(0);
  const [called, setCalled] = useState();
  const [socket, setSocket] = useState();
  const [materialOK, setMaterialOK] = useState(false);
  const [nextConsultation, setNextConsultation] = useState();
  const [openMarkModal, setOpenMarkModal] = useState(false);
  const [openMotiveModal, setOpenMotiveModal] = useState(false);

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  const reset = () => {
    setScreen(0);
    setCallAccepted(false);
    setCallEnded(false);
    setCalled();
    setOpenMarkModal(!openMarkModal);
  };

  const leaveCall = async (reason) => {
    if (stream) {
      await stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }

    setCallEnded(true);
    reset();

    connectionRef.current = null;
    if (reason === 'callEnded') socket.emit('callEnded');
  };

  useEffect(() => {
    if (socket) {
      socket.on('userDisconnected', async (data) => {
        if (data === called && callAccepted) {
          await leaveCall('userDisconnected');
        }
      });
    }
  }, [socket, called]);

  const callUser = (id, consultation) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('callUser', {
        userToCall: id,
        signalData: data,
        from: user._id,
        name,
        consultation
      });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on('callAccepted', (signal) => {
      setCallAccepted(true);
      setCallEnded(false);
      setCalled(id);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const getNextConsultation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'consultations/next-consultation?populate=patient'
      });
      setNextConsultation(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token)
      (async () => {
        await getNextConsultation();
      })();
  }, [callEnded]);

  useEffect(() => {
    const storedMaterialOK = localStorage.getItem('materialOK');
    if (storedMaterialOK) {
      setMaterialOK(JSON.parse(storedMaterialOK));
    }
  }, []);

  return (
    <SocketContext.Provider
      value={{
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        name,
        setName,
        callEnded,
        callUser,
        leaveCall,
        socket,
        screen,
        setScreen,
        materialOK,
        setMaterialOK,
        nextConsultation,
        openMarkModal,
        setOpenMarkModal,
        openMotiveModal,
        setOpenMotiveModal
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined)
    throw new Error('Context must be used within a provider');
  return context;
};
