import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Rate } from 'antd';
import PropTypes from 'prop-types';
import { useSocketContext } from '../SocketContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';

export const CallEvaluationPopup = ({ id }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { openMarkModal, setOpenMarkModal } = useSocketContext();
  const [form] = Form.useForm();

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setOpenMarkModal(false);
  };

  const postCallMark = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `consultations/${id}`,
        body
      });
    } catch (e) {
      message(e);
    }
  };

  const onFinish = async (values) => {
    await postCallMark(values);
    form.resetFields();
    setOpenMarkModal(!openMarkModal);
  };

  return (
    <Modal
      onOk={(e) => {
        handleCloseModal(e);
      }}
      onClose={(e) => handleCloseModal(e)}
      onCancel={(e) => handleCloseModal(e)}
      maskClosable={false}
      width="80%"
      open={openMarkModal}
      footer={null}
      className="consultation-creation-modal"
      title={t('consultations.modal.note_your_call')}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={['doctor_call_mark']}
          style={{ display: 'flex', justifyContent: 'center' }}
          rules={[{ required: true }]}
        >
          <Rate />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            {t('buttons.send')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CallEvaluationPopup.propTypes = {
  id: PropTypes.string
};

CallEvaluationPopup.defaultProps = {
  id: undefined
};
