import { Button, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocketContext } from './VideoConferencing/SocketContext';
import { routes } from '../../utils/constants/adminRoutes';

export const NextConsultation = () => {
  const { t } = useTranslation();
  const { nextConsultation, setScreen } = useSocketContext();
  const [timeDiff, setTimeDiff] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let intervalTime;
    if (nextConsultation) {
      intervalTime = setInterval(() => {
        const now = moment();
        const diffInMinutes = moment(nextConsultation.date).diff(
          now,
          'minutes'
        );
        setTimeDiff(diffInMinutes);
      }, 600);
    }
    return () => clearInterval(intervalTime);
  }, [nextConsultation]);

  return (
    <div>
      {Math.abs(timeDiff) < 60 && nextConsultation && (
        <Row
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: timeDiff > 0 ? '#2DE180' : '#F92D2F',
            color: 'white'
          }}
        >
          <Row>{t('consultations.next-consultation.consultation_planned')}</Row>
          <Row>{`${t('consultations.next-consultation.for')} ${moment(
            nextConsultation.date
          ).format('HH:mm')}`}</Row>
          <Row>{`${t(
            'consultations.next-consultation.with'
          )} ${nextConsultation.patient.first_name.toUpperCase()} ${nextConsultation.patient.last_name.toUpperCase()}`}</Row>
          <Row>
            {timeDiff <= 0
              ? t('consultations.next-consultation.should_have_begun')
              : t('consultations.next-consultation.begins_in')}
          </Row>
          <Row>{`${
            timeDiff <= 0
              ? `${t(`consultations.next-consultation.ago`)} ${Math.abs(
                  timeDiff
                )}`
              : timeDiff
          } ${t('consultations.next-consultation.minutes')}`}</Row>
          <Row>
            <Button
              onClick={() => {
                navigate(
                  `${routes.CONSULTATIONS}/show/${nextConsultation._id}`
                );
                setScreen(1);
              }}
            >
              {t('consultations.next-consultation.access_waiting_room')}
            </Button>
          </Row>
        </Row>
      )}
    </div>
  );
};
