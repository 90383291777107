import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';

import Calendar from './CalendarBody.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { GenericEvent, CalendarContainerProps } from './types.tsx';
import { daysToWeekObject } from './utils.ts';
import { createEvents } from './createEvents.tsx';

export function WeeklyCalendar<T extends GenericEvent>({
  events,
  onEventClick,
  onSelectDate,
  dayRange = 'no-weekend',
  hourStart = 0,
  hourEnd = 26,
  value,
  weekConfiguration = 'ISO',
  modes,
  mode,
  setMode,
  weekCellRender,
  extraHeader,
  noAllDayRow
}: CalendarContainerProps<T>) {
  const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));
  const weekPeriod = {
    startDate: startWeek,
    endDate: endOfWeek(startWeek),
    weekConfiguration
  };

  useEffect(() => {
    if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
      setStartWeek(value);
    }
  }, [value]);

  useEffect(() => {
    onSelectDate && onSelectDate(startWeek);
  }, [startWeek]);

  const createEventsFromRawData = createEvents(events, weekCellRender);

  const weekObject = daysToWeekObject(createEventsFromRawData, startWeek);

  return (
    <>
      <CalendarHeader
        startWeek={startWeek}
        setStartWeek={setStartWeek}
        weekConfiguration={weekConfiguration}
        modes={modes}
        mode={mode}
        setMode={setMode}
        extraHeader={extraHeader}
      />
      <Calendar
        weekDates={weekPeriod}
        getDayEvents={weekObject}
        onEventClick={onEventClick}
        dayRange={dayRange}
        hourStart={hourStart}
        hourEnd={hourEnd}
        noAllDayRow={noAllDayRow}
      />
    </>
  );
}
