/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Spin, DatePicker, Switch, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Option } = Select;

export const GeneralInfoForm = ({ isSubmitting }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [centers, setCenters] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [alreadyEquipped, setAlreadyEquipped] = useState(false);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers' });
      setCenters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    await getCenters();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^0[0-9]{9}$|^[1-9][0-9]{8}$/;
    if (value && !phoneRegex.test(value)) {
      callback(t('patients.form.phone_number.verification'));
    } else {
      callback();
    }
  };

  const handleAlreadyEquippedChange = useCallback((value) => {
    setAlreadyEquipped(value);
  }, []);

  return (
    <Card title={t('patients.form.info_necessary')}>
      <Spin spinning={isLoading}>
        <Form.Item
          name={['gender']}
          label={t('patients.form.gender')}
          rules={[{ required: true }]}
        >
          <Select loading={enums?.gendersLoading}>
            {(enums?.genders || []).map((gender) => (
              <Option key={gender} value={gender}>
                {t(`patients.genders.${gender}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['last_name']}
          label={t('patients.form.last_name')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['first_name']}
          label={t('patients.form.first_name')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['center']}
          label={t('patients.form.center')}
          rules={[{ required: true }]}
        >
          <Select loading={!centers.length}>
            {centers.map((center) => (
              <Option key={center._id} value={center._id}>
                {center.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={['email']}
          label={t('patients.form.email')}
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['phone_number', 'number']}
          label={t('patients.form.phone_number.number')}
          rules={[
            {
              required: true,
              message: t('patients.form.phone_number.request')
            },
            { validator: validatePhone }
          ]}
        >
          <Input type="number" addonBefore="+33" />
        </Form.Item>
        <Form.Item
          name={['date_of_birth']}
          label={t('patients.form.date_of_birth')}
          rules={[{ required: true }]}
        >
          <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          name={['already_equipped']}
          label={t('patients.form.already_equipped')}
          initialValue={alreadyEquipped}
          valuePropName="checked"
        >
          <Switch
            loading={isSubmitting}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={handleAlreadyEquippedChange}
          />
        </Form.Item>
      </Spin>
    </Card>
  );
};

GeneralInfoForm.propTypes = {
  isSubmitting: PropTypes.bool
};

GeneralInfoForm.defaultProps = {
  isSubmitting: null
};
