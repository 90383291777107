import { useTranslation } from 'react-i18next';

export const recurrenceDetails = (data) => {
  const { t } = useTranslation();

  const detailsDisplay = () => {
    switch (data?.periodicity) {
      case 'DAILY':
        if (data?.every_N_day) {
          return `${t('unavailabilities.show.every')} ${
            data?.every_N_day > 1 ? data?.every_N_day : ''
          } ${t('unavailabilities.show.days')}`;
        }
        return t('unavailabilities.show.every_days');
      case 'WEEKLY':
        return `${t('unavailabilities.show.every')} ${
          data?.every_N_week > 1 ? data?.every_N_week : ''
        } ${t('unavailabilities.show.weeks')} ${
          data?.weekly_days?.length === 6
            ? t('unavailabilities.show.every_days_low')
            : `${t('unavailabilities.show.the_low')} ${data?.weekly_days?.map(
                (day) => `${t(`unavailabilities.show.${day}`)} `
              )}`
        }
      `;
      case 'MONTHLY':
        if (data?.the_N_monthday_every_N_month) {
          return `${t('unavailabilities.show.the')} ${
            data?.the_N_monthday_every_N_month?.N_monthday
          } ${t('unavailabilities.show.every_low')} ${
            data?.the_N_monthday_every_N_month?.N_month > 1
              ? data?.the_N_monthday_every_N_month?.N_month
              : ''
          } ${t('unavailabilities.show.months')}`;
        }
        return `${t('unavailabilities.show.the')} ${t(
          `unavailabilities.show.count.${data?.the_N_count_N_weekday_every_N_month?.N_count}`
        )} 
      ${t(
        `unavailabilities.show.${data?.the_N_count_N_weekday_every_N_month?.N_weekday}`
      )} ${t('unavailabilities.show.of_the_month')}
      ${t('unavailabilities.show.every_low')} ${
          data?.the_N_count_N_weekday_every_N_month?.N_weekday
        } ${t('unavailabilities.show.months')}`;
      default:
        return `${t('unavailabilities.show.the')} ${
          data?.the_N_monthday_N_month?.N_monthday
        } ${t(
          `unavailabilities.show.monthsList.${data?.the_N_monthday_N_month?.N_month}`
        )}`;
    }
  };

  return detailsDisplay();
};
