import React, { useCallback, useState, useEffect } from 'react';
import { Input, Select, DatePicker } from 'antd';
// import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  // const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [patients, setPatients] = useState([]);
  const [users, setUsers] = useState([]);
  const [centers, setCenters] = useState([]);

  const fields = [
    {
      name: ['patient'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(patients || []).map((patient) => (
            <Option key={patient._id} value={patient._id}>
              {`${patient.first_name} ${patient.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['center'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(centers || []).map((center) => (
            <Option key={center._id} value={center._id}>
              {center.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['doctor'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(users || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['specialist'],
      required: true,
      input: (
        <Select loading={isFieldsLoading}>
          {(users || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['report'],
      rules: [{ required: true }],
      input: <TextArea rows={4} style={{ width: '100%' }} />
    },
    {
      name: ['anamnese'],
      rules: [{ required: true }],
      input: <TextArea rows={4} style={{ width: '100%' }} />
    }
  ];

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/centers' });
      setCenters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPatients = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients' });
      setPatients(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCenters();
    await getUsers();
    await getPatients();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
