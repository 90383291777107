export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const consultationTypes = {
  IMMEDIATE: '#7011AB',
  BOOKED: '#016FD0'
};

export const consultationStatus = {
  PLANNED: '#199D90',
  DONE: '#0DC815',
  CANCELLED: '#F92D2F',
  DELAYED: '#F48D41',
  ARCHIVED: '#242424'
};
