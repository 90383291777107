import { Button, Col, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { routes } from '../../utils/constants/adminRoutes';
import { useState } from 'react';
import { unavailabilityCellstyleOptions } from './MonthAnnualCalendar/unavailabilityCellstyleOptions';
import { useHomeContext } from '../../contexts/HomeContext';
import { UserOutlined } from '@ant-design/icons';

export const weekCellRender = (eventItem: {
  id: string;
  date: Date;
  startTime?: string;
  endTime?: string;
  type: string;
  patient: {
    first_name: string;
    last_name: string;
  };
}) => {
  if (eventItem.type === 'unavailability') {
    return (
      <div
        className="unavailability-event-calendar"
        style={{ backgroundColor: '#DADADA' }}
      />
    );
  }
  return (
    <Link
      to={{
        pathname: `${routes.CONSULTATIONS}/show/${eventItem.id}`
      }}
      className="consultation-event-calendar"
    >
      <Row>
        <Col>{moment(eventItem.date).format('HH:mm')}</Col>
        <Col>{`${eventItem.patient.first_name} ${eventItem.patient.last_name}`}</Col>
      </Row>
    </Link>
  );
};

export const monthCellRender = (value: moment.Moment, events: Array<any>) => {
  const date = value;
  const [cellClass, setCellClass] = useState<string>();

  // Filter should not be changed or just for specific cases
  const consultationsToDisplay = (events || []).filter(
    (event) =>
      moment(date).day() !== 0 &&
      moment(date).format('YYYY-MM-DD') >=
        moment(event.date).format('YYYY-MM-DD') &&
      moment(date).format('YYYY-MM-DD') <=
        moment(event.date).format('YYYY-MM-DD') &&
      moment(date).isoWeekday() !== 7 &&
      moment(date).isoWeekday() !== 6 &&
      event.type === 'consultation'
  );

  const doctorUnavailabilities = events.filter(
    (event) => event.type === 'unavailability'
  );

  const dateCellRenderOptions = () => {
    if (consultationsToDisplay.length >= 3 && cellClass !== 'custom-cell') {
      return (
        <Col style={{ minWidth: '100%' }}>
          <Row style={{ minWidth: '100%', marginBottom: '1px' }}>
            <li
              key={consultationsToDisplay[0]._id}
              style={{ width: '100%', display: 'block' }}
            >
              <Link
                to={{
                  pathname: `${routes.CONSULTATIONS}/show/${consultationsToDisplay[0]._id}`
                }}
              >
                <Tag>
                  <Row
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Col>
                      {moment(consultationsToDisplay[0]?.date).format('LT')}
                    </Col>
                    <Col>{`${consultationsToDisplay[0]?.patient?.first_name} ${consultationsToDisplay[0]?.patient?.last_name}`}</Col>
                  </Row>
                </Tag>
              </Link>
            </li>
          </Row>
          <Row>
            <Col span={19}>
              <li
                key={consultationsToDisplay[1]._id}
                style={{ width: '100%', display: 'block' }}
              >
                <Link
                  to={{
                    pathname: `${routes.CONSULTATIONS}/show/${consultationsToDisplay[1]._id}`
                  }}
                >
                  <Tag>
                    <Row
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Col>{`${consultationsToDisplay[0]?.patient?.first_name} ${consultationsToDisplay[1]?.patient?.last_name}`}</Col>
                    </Row>
                  </Tag>
                </Link>
              </li>
            </Col>
            <Col span={4}>
              <Button
                className="light-btn"
                onClick={() => setCellClass('custom-cell')}
              >
                ...
              </Button>
            </Col>
          </Row>
        </Col>
      );
    }
    return consultationsToDisplay.map((consultation) => (
      <li key={consultation._id}>
        <Link
          to={{
            pathname: `${routes.CONSULTATIONS}/show/${consultation._id}`
          }}
        >
          <Tag>
            <Row
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Col>{moment(consultation?.date).format('LT')}</Col>
              <Col>{`${consultation?.patient?.first_name} ${consultation?.patient?.last_name}`}</Col>
            </Row>
          </Tag>
        </Link>
      </li>
    ));
  };

  const backgroundRender = () => {
    if (doctorUnavailabilities) {
      return unavailabilityCellstyleOptions(date, doctorUnavailabilities);
    }
    return 'white';
  };

  return (
    <div
      style={{
        backgroundColor: backgroundRender()
      }}
      className={cellClass || ''}
      key={moment(date).format('DD-MM-YYYY')}
    >
      {consultationsToDisplay.length > 0 && (
        <Row className="consultations-counter">
          <UserOutlined />
          {consultationsToDisplay.length}
        </Row>
      )}
      {dateCellRenderOptions()}
    </div>
  );
};

export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <div key={moment(value).format('DD-MM-YYY')}>
      {(events || [])
        // Filter and sort should not be changed or just for specific cases
        .filter(
          (event) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort((a, b) => moment(b.date).day() - moment(a.date).day())
        // Map is your customizable part
        .map((event) => (
          <li key={event._id}>
            <Link
              to={{
                pathname: `${routes.CONSULTATIONS}/show/${event._id}`
              }}
            >
              {moment(event.date).format('DD-MM-YYYY')}
            </Link>
          </li>
        ))}
    </div>
  );
};
