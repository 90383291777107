import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
body {
  --clientColor: #fff;
  --primaryColor: #199d90;
  --addColor: #47d267;
  --itemHoverColor: var(--primaryColor);
  --componentBackground: #fff;
  --secondaryHover: var(--componentBackground);
  --subMenuBackground: var(--componentBackground);
  --menuDropdownBackground: var(--componentBackground);
  --pickerDropdown: var(--componentBackground);
  --primaryHover: rgba(25, 157, 144, 0.08);
  --secondaryColor: #2de180;
  --disabledColor: #bfbfbf;
  --bodyBackground: #FCFCFC;
  --textColor: #172833;
  --textColorSecondary: rgba(0, 0, 0, 0.45);
  --itemActiveBackground: #e6f7ff;
  --itemHoverBackground: #f0f0f0;
  --borderColor: #d9d9d9;
  --secondaryBorderColor: rgba(9, 9, 9, 0.2);
  --contrastBackground: #f5f5f5;
  --errorColor: #e31919;
  --opacitySVG: 1;
  --primaryColorA04
  --primaryColorA07
  --primaryContrast
  --tabsGray
  --textColorInvert
  --textColorHover
  --textColorA015
  --textColorA075
  --borderColorA06
  --disabledColorA04
  --disabledColorA06
  --itemActiveColor
  --logo
  --logoSmall
  --error100
  --errorA08
}
`;
