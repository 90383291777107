import { Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHomeContext } from '../../contexts/HomeContext';
import { ConsultationManagementPopover } from '../../components/Popover/ConsultationManagementPopover';

const { Search } = Input;

export const CustomHeader = () => {
  const { t } = useTranslation();
  const { searchFilter, setSearchFilter, forceRefresh, setForceRefresh } =
    useHomeContext();

  const searchResource = (val) => {
    setSearchFilter(val);
    setForceRefresh(!forceRefresh);
  };

  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 1rem',
        background: 'var(--componentBackground)'
      }}
    >
      <Col>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchFilter}
          onSearch={(val) => searchResource(val)}
        />
      </Col>
      <ConsultationManagementPopover location="home" />
    </Row>
  );
};
