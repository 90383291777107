import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton, Row, Avatar, Card, Space, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { FilesManagement } from './FilesManagement';
import { useDocuments } from './useDocuments';
import { UserArrays } from './UserArrays';
import { useDownloadDocument } from '../../utils/downloadDoc';

const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [doneConsultations, setDoneConsultations] = useState([]);
  const { downloadDocument } = useDownloadDocument();
  const documents = user && useDocuments(user, t);

  const downloadDocuments = () => {
    documents.forEach((document) => downloadDocument(document.file));
  };

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=photo,IDCard,doctorDiplom,otologyAttestation,BAI,signatureStamp,extraDocuments`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getUserDoneConsultations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `consultations?status=DONE&populate=patient,specialist`
      });
      setDoneConsultations(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUserDoneConsultations();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser, forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={`${user?.first_name?.toUpperCase()} ${user?.last_name?.toUpperCase()}`}
        extra={
          <Space>
            <Button type="primary" onClick={downloadDocuments}>
              <DownloadOutlined />
              {t('users.show.download_files')}
            </Button>
          </Space>
        }
      />
      <ContentCustom>
        <Card title={t('users.show.doctor_infos')}>
          <Row gutter={[0, 24]} align="middle">
            <Col
              style={{ display: 'flex' }}
              xs={24}
              md={8}
              lg={6}
              xl={4}
              xxl={3}
            >
              <Skeleton
                loading={isLoading}
                title={0}
                paragraph={0}
                avatar={{ size: 120 }}
                active
              >
                <Avatar
                  style={{ margin: 'auto' }}
                  size={120}
                  src={user && user.photo ? user.photo : ``}
                />
              </Skeleton>
            </Col>
            <Col xs={24} md={16} lg={18} xl={20} xxl={21}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList data={listContent(user || {}, t)} translate />
              </Skeleton>
            </Col>
          </Row>
        </Card>
        <FilesManagement
          documents={documents}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
        />
        <UserArrays doneConsultations={doneConsultations} />
      </ContentCustom>
    </>
  );
};

export default ShowUser;
