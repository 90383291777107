export const initialMainChartDataState = (mainPointCoordinates) => ({
  datasets: [
    {
      id: 'main',
      data: [mainPointCoordinates],
      backgroundColor: 'red',
      borderColor: 'black',
      pointStyle: 'rect',
      pointRadius: 10,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'white',
      pointBorderColor: 'black',
      pointHoverBorderColor: 'red',
      type: 'scatter'
    },
    {
      id: 'Earphones - Left Ear',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'Earphones - Right Ear',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'Transducer - Left Ear',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'Transducer - Right Ear',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'Audio Field without hearingAid',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'Audio Field with hearingAid',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'UCL LeftEar',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    },
    {
      id: 'UCL RightEar',
      data: [],
      backgroundColor: 'red',
      borderColor: 'white',
      pointStyle: 'image',
      pointRadius: 20,
      pointHoverRadius: 10,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointHoverBackgroundColor: 'none',
      pointBorderColor: 'white',
      pointHoverBorderColor: 'white',
      type: 'scatter'
    }
  ]
});
