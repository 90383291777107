import { Radio, Form, Row, InputNumber, Checkbox, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  frequencies,
  useCountOptions,
  useDaysOptions,
  useMonthOptions
} from './frequencyOptions';

export const FrequencyForm = ({ periodicity, setPeriodicity }) => {
  const { t } = useTranslation();

  const [subValue, setSubValue] = useState();

  const daysOptions = useDaysOptions(t);
  const countOptions = useCountOptions(t);
  const monthOptions = useMonthOptions(t);

  return (
    <>
      <Row justify="center">
        <Form.Item name={['frequency', 'periodicity']}>
          <Radio.Group
            value={periodicity}
            onChange={(e) => setPeriodicity(e.target.value)}
          >
            {frequencies.map((enumItem) => (
              <Radio value={enumItem}>
                {t(`unavailabilities.form.frequency.${enumItem}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row justify="center" className="unavailability-frequency">
        {periodicity === 'DAILY' && (
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column' }}
            value={subValue}
            onChange={(e) => setSubValue(e.target.value)}
          >
            <Radio value={1}>
              <Row style={{ alignItems: 'center' }}>
                <Row>
                  <Form.Item
                    name={['frequency', 'every_N_day']}
                    label={t('unavailabilities.form.frequency.every_N_day')}
                  >
                    <InputNumber min={0} max={7} disabled={subValue !== 1} />
                  </Form.Item>
                </Row>
                <Row>{t('unavailabilities.form.frequency.days')}</Row>
              </Row>
            </Radio>
            <Form.Item
              name={['frequency', 'weekly_days']}
              valuePropName="checked"
            >
              <Radio value={2}>
                {t('unavailabilities.form.frequency.all_week_days')}
              </Radio>
            </Form.Item>
          </Radio.Group>
        )}
        {periodicity === 'WEEKLY' && (
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column' }}
            value={subValue}
            onChange={(e) => setSubValue(e.target.value)}
          >
            <Form.Item
              label={t('unavailabilities.form.frequency.every_N_week')}
            >
              <Form.Item name={['frequency', 'every_N_week']}>
                <InputNumber min={0} max={53} />
              </Form.Item>
              {t('unavailabilities.form.frequency.weeks')}
            </Form.Item>
            <Form.Item
              name={['frequency', 'weekly_days']}
              label={t('unavailabilities.form.frequency.day_choice')}
            >
              <Checkbox.Group options={daysOptions} valuePropName="checked" />
            </Form.Item>
          </Radio.Group>
        )}
        {periodicity === 'MONTHLY' && (
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column' }}
            value={subValue}
            onChange={(e) => setSubValue(e.target.value)}
          >
            <Radio value={1}>
              <Form.Item
                name={[
                  'frequency',
                  'the_N_monthday_every_N_month',
                  'N_monthday'
                ]}
                label={t('unavailabilities.form.frequency.the')}
              >
                <InputNumber min={0} max={31} disabled={subValue !== 1} />
              </Form.Item>
              <Form.Item
                name={['frequency', 'the_N_monthday_every_N_month', 'N_month']}
                label={t('unavailabilities.form.frequency.every')}
              >
                <InputNumber min={0} max={12} disabled={subValue !== 1} />
              </Form.Item>
              {t('unavailabilities.form.frequency.months')}
            </Radio>
            <Radio value={2}>
              <Form.Item
                name={[
                  'frequency',
                  'the_N_count_N_weekday_every_N_month',
                  'N_count'
                ]}
                label={t('unavailabilities.form.frequency.the')}
              >
                <Select>
                  {countOptions.map((option) => (
                    <Select.Option value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={[
                  'frequency',
                  'the_N_count_N_weekday_every_N_month',
                  'N_weekday'
                ]}
              >
                <Select>
                  {daysOptions.map((option) => (
                    <Select.Option value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={[
                  'frequency',
                  'the_N_count_N_weekday_every_N_month',
                  'N_month'
                ]}
                label={t('unavailabilities.form.frequency.every')}
              >
                <InputNumber min={0} max={12} disabled={subValue !== 2} />
              </Form.Item>
              {t('unavailabilities.form.frequency.months')}
            </Radio>
          </Radio.Group>
        )}
        {periodicity === 'ANNUAL' && (
          <>
            {t('unavailabilities.form.frequency.the')}
            <Form.Item
              name={['frequency', 'the_N_monthday_N_month', 'N_monthday']}
            >
              <InputNumber min={0} max={31} />
            </Form.Item>
            <Form.Item
              name={['frequency', 'the_N_monthday_N_month', 'N_month']}
            >
              <Select style={{ width: 150 }}>
                {monthOptions.map((option) => (
                  <Select.Option value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Row>
    </>
  );
};

FrequencyForm.propTypes = {
  periodicity: PropTypes.string,
  setPeriodicity: PropTypes.func
};

FrequencyForm.defaultProps = {
  periodicity: undefined,
  setPeriodicity: null
};
