import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/doctorsRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';

import { ConsultationRouter } from '../routes/consultations/ConsultationRouter';
import { PrescriptionRouter } from '../routes/prescriptions/PrescriptionRouter';
import { PatientRouter } from '../routes/patients/PatientRouter';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { HomeContextProvider } from '../contexts/HomeContext';
import { UnavailabilityRouter } from '../routes/unavailabilities/UnavailabilityRouter';
import { SocketContextProvider } from '../routes/consultations/VideoConferencing/SocketContext';
import { MaterialTest } from '../routes/consultations/VideoConferencing/components/MaterialTest';
import ShowUser from '../routes/users/ShowUser';

const RequireAuth = () => {
  const { isValid } = useAuthContext();
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const DoctorRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <HomeContextProvider>
        <SocketContextProvider>
          <UserTheme />
          <Routes>
            <Route
              path={`${outOfNavRoutes.LOGIN}/:token`}
              element={<Login />}
            />
            <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
            <Route
              path={`${outOfNavRoutes.CONFIRMATION}/:token`}
              element={<Confirmation />}
            />
            <Route element={<RequireAuth />}>
              <Route
                path={`${outOfNavRoutes.PROFILE}/*`}
                element={<ProfileRouter />}
              />
              <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
              <Route path={routes.HOME} element={<Home />} />
              <Route
                path="material-test"
                element={<MaterialTest purpose="GLOBAL" />}
              />
              <Route
                path={`${routes.PATIENTS}/*`}
                element={<PatientRouter />}
              />
              <Route
                path={`${routes.UNAVAILABILITIES}/*`}
                element={<UnavailabilityRouter />}
              />
              <Route
                path={`${routes.CONSULTATIONS}/*`}
                element={<ConsultationRouter />}
              />
              <Route
                path={`${routes.PRESCRIPTIONS}/*`}
                element={<PrescriptionRouter />}
              />
              <Route path={`${routes.USERS}/show/:id`} element={<ShowUser />} />
              <Route path="*" element={<Exception />} />
            </Route>
          </Routes>
        </SocketContextProvider>
      </HomeContextProvider>
    </BrowserRouter>
  );
};
