import LeftEar from '../../../../../../assets/images/left-ear.svg';
import RightEar from '../../../../../../assets/images/right-ear.svg';
import ActivatedTransducer from '../../../../../../assets/images/activatedTransducer.svg';
import ActivatedEarphones from '../../../../../../assets/images/activatedEarphones.svg';
import ActivatedAudioField from '../../../../../../assets/images/activatedAudioField.svg';
import DeactivatedTransducer from '../../../../../../assets/images/deactivatedTransducer.svg';
import DeactivatedEarphones from '../../../../../../assets/images/deactivatedEarphones.svg';
import DeactivatedAudioField from '../../../../../../assets/images/deactivatedAudioField.svg';

export const svgFiles = {
  LeftEar,
  RightEar,
  ActivatedTransducer,
  ActivatedEarphones,
  ActivatedAudioField,
  DeactivatedTransducer,
  DeactivatedEarphones,
  DeactivatedAudioField
};
