import { useTranslation } from 'react-i18next';
import { Table, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useColumns } from './columns';

export const UserArrays = ({ doneConsultations }) => {
  const { t } = useTranslation();
  const consultationsDoneColumns = useColumns();

  return (
    <Tabs defaultActiveKey="1" tabPosition="top">
      <Tabs.TabPane tab={t('users.show.tabs.consultations')} key="1">
        <Table
          columns={consultationsDoneColumns}
          dataSource={doneConsultations}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

UserArrays.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    _id: PropTypes.string,
    center: PropTypes.string
  }),
  doneConsultations: PropTypes.arrayOf(PropTypes.shape({}))
};

UserArrays.defaultProps = {
  user: null,
  doneConsultations: null
};
