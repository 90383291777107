import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  DownloadOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

const { Title } = Typography;

const ShowPrescription = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [prescription, setPrescription] = useState(null);

  const getPrescription = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prescriptions/${id}?populate=consultation,consultation.center,consultation.patient,consultation.specialist`
      });
      setPrescription(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getPrescription();
    })();
  }, [getPrescription]);

  const deletePrescription = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/prescriptions/${id}`
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${
          `${moment(prescription?.date).format('DD/MM/YYYY')} - ${
            prescription?.patient.first_name
          } ${prescription?.patient.first_name}` || 'Prescription'
        }`}
        extra={
          <>
            <Link to={{ pathname: `${routes.CONSULTATIONS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Button type="primary">
              {`${t('buttons.download_documents')} `}
              <DownloadOutlined />
            </Button>
            <Button type="primary">
              {`${t('buttons.start_prescription')} `}
              <TeamOutlined />
            </Button>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePrescription}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <ContentCustom>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Title span={4}>INFORMATIONS CONSULTATION</Title>
            <DescriptionList
              data={listContent(prescription || {}, t)}
              translate
            />
          </Skeleton>
        </ContentCustom>
      </Row>

      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <ContentCustom style={{ marginTop: 10 }}>
          <Row>FILES</Row>
        </ContentCustom>
      </Row>
    </>
  );
};

export default ShowPrescription;
