import sound125 from '../../../../../../assets/sounds/125.wav';
import sound250 from '../../../../../../assets/sounds/250.wav';
import sound500 from '../../../../../../assets/sounds/500.wav';
import sound750 from '../../../../../../assets/sounds/750.wav';
import sound1000 from '../../../../../../assets/sounds/1000.wav';
import sound1500 from '../../../../../../assets/sounds/1500.wav';
import sound2000 from '../../../../../../assets/sounds/2000.wav';
import sound3000 from '../../../../../../assets/sounds/3000.wav';
import sound4000 from '../../../../../../assets/sounds/4000.wav';
import sound6000 from '../../../../../../assets/sounds/6000.wav';
import sound8000 from '../../../../../../assets/sounds/8000.wav';

export const otoscopyAudios = () => {
  const audio125 = new Audio(sound125);
  const audio250 = new Audio(sound250);
  const audio500 = new Audio(sound500);
  const audio750 = new Audio(sound750);
  const audio1000 = new Audio(sound1000);
  const audio1500 = new Audio(sound1500);
  const audio2000 = new Audio(sound2000);
  const audio3000 = new Audio(sound3000);
  const audio4000 = new Audio(sound4000);
  const audio6000 = new Audio(sound6000);
  const audio8000 = new Audio(sound8000);

  return [
    {
      frequency: 125,
      audio: audio125
    },
    {
      frequency: 250,
      audio: audio250
    },
    {
      frequency: 500,
      audio: audio500
    },
    {
      frequency: 750,
      audio: audio750
    },
    {
      frequency: 1000,
      audio: audio1000
    },
    {
      frequency: 1500,
      audio: audio1500
    },
    {
      frequency: 2000,
      audio: audio2000
    },
    {
      frequency: 3000,
      audio: audio3000
    },
    {
      frequency: 4000,
      audio: audio4000
    },
    {
      frequency: 6000,
      audio: audio6000
    },
    {
      frequency: 8000,
      audio: audio8000
    }
  ];
};
