import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Space, Switch } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;

export const ExtraSubHeader = ({
  setRangeDate,
  switchStatus,
  setSwitchStatus,
  showAll,
  setShowAll
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col>
        <RangePicker onChange={(value) => setRangeDate(value)} />
      </Col>
      <Col>
        <Space>
          {t('consultations.form.planned_consultations')}
          <Switch onChange={() => setSwitchStatus(!switchStatus)} />
          {t('consultations.form.cancelled_consultations')}
        </Space>
      </Col>
      {switchStatus && (
        <Col>
          <Button onClick={() => setShowAll(!showAll)}>
            {!showAll ? (
              <Space>
                <EyeOutlined />
                {t('consultations.form.show_all')}
              </Space>
            ) : (
              <Space>
                <EyeInvisibleOutlined />
                {t('consultations.form.hide')}
              </Space>
            )}
          </Button>
        </Col>
      )}
    </>
  );
};

ExtraSubHeader.propTypes = {
  setRangeDate: PropTypes.func,
  setSwitchStatus: PropTypes.func,
  setShowAll: PropTypes.func,
  showAll: PropTypes.bool,
  switchStatus: PropTypes.bool
};

ExtraSubHeader.defaultProps = {
  setRangeDate: null,
  setSwitchStatus: null,
  setShowAll: null,
  showAll: undefined,
  switchStatus: undefined
};
