import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton, Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import listContent from '../listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList,
  Datatable
} from '../../../components';
import { FilesManagement } from './components/FilesManagement';
import { useDocuments } from './useDocuments';
import { useColumns } from '../../consultations/columns';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const ShowPatient = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState(null);
  const documents = patient && useDocuments(patient, t);
  const consultationColumns = useColumns();
  const { downloadDocument } = useDownloadDocument();

  const downloadDocuments = () => {
    documents.forEach((document) => downloadDocument(document.file));
  };

  const getPatient = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}?populate=vital_card,anamnese,otoscopy,audiogram_actions,prescription,files,center`
      });
      setPatient(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getPatient();
    })();
  }, [getPatient]);

  return (
    <>
      <PageHeaderCustom
        title={`${patient?.first_name?.toUpperCase()} ${patient?.last_name?.toUpperCase()}`}
        extra={
          <Button type="primary" onClick={downloadDocuments}>
            {`${t('buttons.download_documents')} `}
            <DownloadOutlined />
          </Button>
        }
      />
      <ContentCustom>
        <Row>
          <Card title={t('patients.show.patient_infos')}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent(patient || {})} translate />
            </Skeleton>
          </Card>
        </Row>
        <FilesManagement patient={patient} documents={documents} />
        {patient && (
          <Card title={t('patients.show.consultations_done')}>
            <Datatable
              resourceName="consultations"
              extraQuery={`patient=${patient._id}&populate=patient,specialist&status=DONE`}
              columns={consultationColumns}
            />
          </Card>
        )}
      </ContentCustom>
    </>
  );
};

export default ShowPatient;
