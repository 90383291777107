import { Button, Card, Form, Row, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { tailFormItemLayout } from '../../utils/constants/formLayout';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { UnavailabilityRangeForm } from './form/UnavailabilityRangeForm';
import { FrequencyForm } from './form/FrequencyForm';
import { UnavailabilityHourForm } from './form/UnavailabilityHourForm';
import { useHomeContext } from '../../contexts/HomeContext';

export const CreateUpdateUnavailability = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { setForceRefresh, forceRefresh } = useHomeContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [periodicity, setPeriodicity] = useState();

  const updateUnavailability = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `unavailabilities/${id}`,
        body
      });
      navigate(-1);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const createUnavailability = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `unavailabilities`,
        body
      });
      navigate(-1);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getUnavailability = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `unavailabilities/${id}`
      });
      form.setFieldsValue({
        ...data,
        unavailability_range: {
          start:
            data.unavailability_range.start &&
            moment(data.unavailability_range.start),
          end:
            data.unavailability_range.end &&
            moment(data.unavailability_range.end)
        },
        unavailability_hours: {
          all_day: data.unavailability_hours.all_day,
          start_hour:
            data.unavailability_hours.start_hour &&
            moment(data.unavailability_hours.start_hour),
          end_hour:
            data.unavailability_hours.end_hour &&
            moment(data.unavailability_hours.end_hour)
        }
      });
      setPeriodicity(data.frequency.periodicity);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);

      (async () => {
        await getUnavailability();
      })();
    }
  }, [getUnavailability]);

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateUnavailability(values);
    if (purpose === 'create') await createUnavailability(values);
  };

  return (
    <>
      <PageHeaderCustom title={t(`unavailabilities.form.title.${purpose}`)} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={handleSubmit}>
            <Card title={t('unavailabilities.form.unavailability_range.title')}>
              <UnavailabilityRangeForm />
            </Card>
            <Card title={t('unavailabilities.form.frequency.title')}>
              <FrequencyForm
                periodicity={periodicity}
                setPeriodicity={setPeriodicity}
              />
            </Card>
            <Card title={t('unavailabilities.form.unavailability_hours.title')}>
              <UnavailabilityHourForm />
            </Card>
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

CreateUpdateUnavailability.propTypes = {
  purpose: PropTypes.string.isRequired
};
