import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { WeeklyCalendar } from './WeeklyCalendar/WeeklyCalendar.tsx';
import { useAuthContext } from '../../contexts/AuthContext';
import { MonthAnnualCalendar } from './MonthAnnualCalendar/MonthAnnualCalendar.tsx';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const Calendar = ({
  modes,
  resourceName,
  extraQuery,
  weekCellRender,
  monthCellRender,
  annualCellRender,
  extraHeader,
  dayRange,
  customRoute,
  populate,
  forceRefresh,
  hourStart,
  hourEnd,
  noAllDayRow,
  initialMode
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [mode, setMode] = useState(initialMode);

  const fetchEvents = async () => {
    const url = `${resourceName}${customRoute ? `/${customRoute}` : ''}${
      extraQuery ? `?${extraQuery}` : ''
    }${
      populate
        ? `${extraQuery ? `&populate=${populate}` : `?populate=${populate}`}`
        : ''
    }`;

    try {
      const { data } = await dispatchAPI('GET', {
        url
      });
      setEvents(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEvents();
    })();
  }, [forceRefresh]);

  const displayMode = () => {
    switch (mode) {
      case 'week':
        return (
          <WeeklyCalendar
            modes={modes}
            mode="week"
            setMode={setMode}
            events={events}
            weekCellRender={weekCellRender}
            dayRange={dayRange}
            extraHeader={extraHeader}
            hourStart={hourStart}
            hourEnd={hourEnd}
            noAllDayRow={noAllDayRow}
          />
        );
      case 'year':
        return (
          <MonthAnnualCalendar
            mode="year"
            cellRender={annualCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
          />
        );
      default:
        return (
          <MonthAnnualCalendar
            mode="month"
            cellRender={monthCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
          />
        );
    }
  };

  return <Card>{displayMode()}</Card>;
};

Calendar.propTypes = {
  modes: PropTypes.arrayOf(PropTypes.string).isRequired,
  resourceName: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.shape({}),
  monthCellRender: PropTypes.func,
  weekCellRender: PropTypes.func,
  annualCellRender: PropTypes.func,
  dayRange: PropTypes.string,
  customRoute: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.bool,
  hourStart: PropTypes.number,
  hourEnd: PropTypes.number,
  noAllDayRow: PropTypes.bool,
  initialMode: PropTypes.string.isRequired
};

Calendar.defaultProps = {
  extraQuery: undefined,
  extraHeader: null,
  monthCellRender: null,
  weekCellRender: null,
  annualCellRender: null,
  dayRange: undefined,
  customRoute: undefined,
  populate: undefined,
  forceRefresh: undefined,
  hourStart: undefined,
  hourEnd: undefined,
  noAllDayRow: undefined
};
