// Main nav menuItems
export const routes = {
  HOME: '/',
  PATIENTS: '/patients',
  USERS: '/users',
  CONSULTATIONS: '/consultations',
  UNAVAILABILITIES: '/unavailabilities'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
