import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListConsultations from './ListConsultations';
import ShowConsultation from './ShowConsultation/ShowConsultation';
import { PureToneAudiometry } from './VideoConferencing/exercises/PureToneAudiometry/PureToneAudiometry';

export const ConsultationRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowConsultation />} />
    <Route index element={<ListConsultations />} />
    <Route path="/test" element={<PureToneAudiometry />} />
  </Routes>
);
