import { useEffect } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  SoundOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useErrorMessage } from '../../../../utils/ErrorMessage';
import soundTest from '../../../../assets/sounds/soundTest.wav';
import Microphone from '../../../../assets/images/Microphone.svg';
import { useSocketContext } from '../SocketContext';
import { ContentCustom, PageHeaderCustom } from '../../../../components';

export const MaterialTest = ({ purpose }) => {
  const audio1 = new Audio(soundTest);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { stream, setStream, myVideo, setMaterialOK, setScreen, materialOK } =
    useSocketContext();
  const navigate = useNavigate();

  const playSoundTest = () => {
    audio1.play();
  };

  const pidStyle = {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    margin: '5px'
  };

  const colorPids = (vol) => {
    const allPids = [...document.querySelectorAll('.pid')];
    const numberOfPidsToColor = Math.round(vol / 10);
    const pidsToColor = allPids.slice(0, numberOfPidsToColor);

    for (let i = 0; i < allPids.length; i += 1) {
      allPids[i].style.backgroundColor = '#e6e7e8';
    }
    for (let j = 0; j < pidsToColor.length; j += 1) {
      pidsToColor[j].style.backgroundColor = '#69ce2b';
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const currentStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        });
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(currentStream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          colorPids(average);
        };

        setStream(currentStream);
        myVideo.current.srcObject = currentStream;
        setMaterialOK(true);
      } catch (e) {
        message(e);
      }
    })();
  }, []);

  const createPids = () => {
    const Pids = [];
    for (let i = 0; i < 10; i += 1) {
      Pids.push(<div className="pid" style={pidStyle} />);
    }

    return Pids;
  };

  useEffect(() => {
    localStorage.setItem('materialOK', JSON.stringify(materialOK));
  }, [materialOK]);

  return (
    <>
      {purpose === 'GLOBAL' && (
        <PageHeaderCustom
          title={t('consultations.show.material_test')}
          extra={
            <Button onClick={() => navigate(`/consultations`)}>
              <ArrowLeftOutlined />
              {t('consultations.show.back_to_consultations_list')}
            </Button>
          }
        />
      )}
      <ContentCustom>
        <Row>{t('consultations.show.test_material')}</Row>
        <Row>
          <Col style={{ width: '50%' }}>
            {stream ? (
              <video
                playsInline
                muted
                ref={myVideo}
                autoPlay
                className="user-video-player"
              />
            ) : (
              <Spin />
            )}
          </Col>
          <Col style={{ width: '50%' }}>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '33%'
              }}
            >
              <Row style={{ gap: 8 }}>
                <SoundOutlined style={{ fontSize: 36 }} />
                {t('consultations.show.speakers_configuration')}
              </Row>
              <Button onClick={() => playSoundTest()}>
                <CheckOutlined />
                {t('consultations.show.test_speakers')}
              </Button>
            </Row>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '33%'
              }}
            >
              <img src={Microphone} alt="micro-icon" />
              {t('consultations.show.micro_configuration')}
              <div className="pids-wrapper">{createPids()}</div>
            </Row>
            {purpose !== 'GLOBAL' && (
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '33%'
                }}
              >
                <Button onClick={() => setScreen(1)}>
                  <CheckOutlined />
                  {t('consultations.buttons.back_to_waiting_room')}
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

MaterialTest.propTypes = {
  purpose: PropTypes.string
};

MaterialTest.defaultProps = {
  purpose: undefined
};
