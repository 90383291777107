import { createContext, useContext, useEffect, useState } from 'react';
import { useErrorMessage } from '../utils/ErrorMessage';
import { useAuthContext } from './AuthContext';

const HomeContext = createContext({
  isValid: false,
  forceRefresh: undefined,
  setForceRefresh: (_value) => {}
});

export const HomeContextProvider = ({ children }) => {
  const { dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const [patient, setPatient] = useState();
  const [patients, setPatients] = useState();
  const [step, setStep] = useState(1);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [searchFilter, setSearchFilter] = useState();

  const increaseStep = () => {
    setStep(step + 1);
  };

  const decreaseStep = () => {
    setStep(step - 1);
  };

  const getPatients = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'patients'
      });
      setPatients(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        await getPatients();
      })();
    }
  }, [token, forceRefresh]);

  return (
    <HomeContext.Provider
      value={{
        visible,
        setVisible,
        patient,
        setPatient,
        patients,
        increaseStep,
        decreaseStep,
        step,
        forceRefresh,
        setForceRefresh,
        searchFilter,
        setSearchFilter
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => {
  const context = useContext(HomeContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
