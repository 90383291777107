export const useDocuments = (patient, t) => [
  {
    title: t('patients.files.vital_card'),
    file: patient.vital_card,
    type: 'vitalCard'
  },
  {
    title: t('patients.files.anamnese'),
    file: patient.anamnese,
    type: 'anamnese'
  },
  {
    title: t('patients.files.otoscopy'),
    file: patient.otoscopy,
    type: 'otoscopy'
  },
  {
    title: t('patients.files.prescription'),
    file: patient.prescription,
    type: 'prescription'
  },
  {
    title: t('patients.files.audiogram_actions'),
    file: patient.audiogram_actions,
    type: 'audiogram_actions'
  },
  ...(patient.files || []).map((document) => ({
    title: document.metadata.originalName.split('.')[0].toUpperCase(),
    file: document,
    type: 'extraDocuments'
  }))
];
