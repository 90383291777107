import { CloseOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const StepGraphButtons = ({
  setStepChartData,
  activePointIndex,
  setActivePointIndex,
  sensitivityLevels,
  sensitivityLevelsIndex,
  activeDatasetIndex,
  setMainChartData,
  mainChartData,
  stepChartData,
  frequencyLevels,
  frequencyLevelsIndex
}) => {
  const { t } = useTranslation();

  const cancelLastAction = () => {
    setActivePointIndex(activePointIndex - 1);
    return setStepChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [
            ...prevChartData.datasets[0].data.slice(0, activePointIndex),
            ...prevChartData.datasets[0].data.slice(activePointIndex + 1)
          ]
        },
        {
          ...prevChartData.datasets[1],
          data: []
        },
        {
          ...prevChartData.datasets[2]
        }
      ]
    }));
  };

  const resetData = () => {
    const stepDataPoint = stepChartData.datasets[0].data[activePointIndex];
    const mainData = mainChartData.datasets[activeDatasetIndex].data;
    const dataIndex = mainData.findIndex(
      (point) =>
        point.y === stepDataPoint.y &&
        frequencyLevels[frequencyLevelsIndex].tickValue === point.x
    );

    setMainChartData((prevMainChartData) => ({
      ...prevMainChartData,
      datasets: prevMainChartData.datasets.map((dataset, index) => {
        if (index === activeDatasetIndex) {
          return {
            ...dataset,
            data: [
              ...dataset.data.slice(0, dataIndex),
              ...dataset.data.slice(dataIndex + 1)
            ]
          };
        }
        return dataset;
      })
    }));
    setActivePointIndex(0);
    setStepChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ]
        },
        {
          ...prevChartData.datasets[1],
          data: []
        },
        {
          ...prevChartData.datasets[2],
          data: []
        }
      ]
    }));
  };

  return (
    <Row>
      <Button onClick={cancelLastAction}>
        <CloseOutlined />
        {t('consultations.exercises.cancel_action')}
      </Button>
      <Button onClick={resetData}>
        <RedoOutlined />
        {t('consultations.exercises.redo_exercise')}
      </Button>
    </Row>
  );
};

const datasetPropTypes = PropTypes.shape({
  scatterDataset: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
  ).isRequired,
  lineDataset: PropTypes.arrayOf(
    PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
  ),
  threshold: PropTypes.number
});

const exerciseDatasetsPropTypes = PropTypes.shape({
  earphones: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  }),
  audioField: PropTypes.shape({
    withHearingAid: datasetPropTypes.isRequired,
    withoutHearingAid: datasetPropTypes.isRequired
  }),
  transducer: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  })
});

StepGraphButtons.propTypes = {
  setStepChartData: PropTypes.func,
  activePointIndex: PropTypes.number,
  setActivePointIndex: PropTypes.func,
  sensitivityLevels: PropTypes.arrayOf(PropTypes.number),
  sensitivityLevelsIndex: PropTypes.number,
  activeDatasetIndex: PropTypes.number,
  stepChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  mainChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  setMainChartData: PropTypes.func,
  frequencyLevels: PropTypes.arrayOf(
    PropTypes.shape({
      tickValue: PropTypes.number,
      exerciseDatasets: PropTypes.shape({
        threshold: exerciseDatasetsPropTypes,
        UCL: PropTypes.shape({
          leftEar: datasetPropTypes.isRequired,
          rightEar: datasetPropTypes.isRequired
        })
      })
    })
  ),
  frequencyLevelsIndex: PropTypes.number
};

StepGraphButtons.defaultProps = {
  setStepChartData: null,
  activePointIndex: undefined,
  setActivePointIndex: null,
  sensitivityLevels: null,
  sensitivityLevelsIndex: undefined,
  activeDatasetIndex: null,
  stepChartData: null,
  mainChartData: null,
  setMainChartData: null,
  frequencyLevels: null,
  frequencyLevelsIndex: 5
};
