import { Button, Col, Row } from 'antd';
import {
  PlayCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { handleMax, handleMin } from './utils/graphUtils';
import { DbAudioOutput } from './utils/DbAudioOutput';

export const PureToneAudiometrySensitivityButtons = ({
  sensitivityLevels,
  sensitivityLevelsIndex,
  setSensitivityLevelsIndex,
  mainChartData,
  setMainChartData,
  stepChartData,
  setStepChartData,
  activePointIndex,
  audioToPlay,
  dbLevel,
  setMainPointCoordinates
}) => {
  const { t } = useTranslation();

  const handleMinLimit = () => {
    if (sensitivityLevelsIndex === 0) return t('consultations.exercises.min');
    return sensitivityLevels[sensitivityLevelsIndex - 1];
  };

  const handleMaxLimit = () => {
    if (sensitivityLevelsIndex === sensitivityLevels.length - 1)
      return t('consultations.exercises.max');
    return sensitivityLevels[sensitivityLevelsIndex + 1];
  };

  const handleButtons = (direction) => {
    const mainActivePoint = mainChartData.datasets[0].data[activePointIndex];
    const stepActivePoint = stepChartData.datasets[0].data[activePointIndex];

    const mainNewPoint = {
      x: mainActivePoint.x,
      y:
        direction === 'up'
          ? handleMin(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              setMainPointCoordinates,
              'main'
            )
          : handleMax(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              setMainPointCoordinates,
              'main'
            )
    };

    const stepNewPoint = {
      x: stepActivePoint.x,
      y:
        direction === 'up'
          ? handleMin(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              undefined,
              'step'
            )
          : handleMax(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              undefined,
              'step'
            )
    };

    setMainChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [mainNewPoint]
        },
        ...prevChartData.datasets.slice(1)
      ]
    }));

    return setStepChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [
            ...prevChartData.datasets[0].data.slice(0, activePointIndex),
            stepNewPoint,
            ...prevChartData.datasets[0].data.slice(activePointIndex + 1)
          ]
        },
        {
          ...prevChartData.datasets[1]
        }
      ]
    }));
  };

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}
    >
      <Col>
        <Button
          type="ghost"
          onClick={() => {
            DbAudioOutput(dbLevel, audioToPlay);
          }}
        >
          <PlayCircleOutlined />
        </Button>
      </Col>
      <Col>
        <Button type="ghost" onClick={() => handleButtons('up')}>
          <UpCircleOutlined />
        </Button>
      </Col>
      <Col
        style={{ minHeight: '30px', justifyContent: 'center', fontSize: 18 }}
        align="center"
        justify="center"
      >
        {handleMinLimit()}
      </Col>
      <Col
        style={{ minHeight: '30px', fontSize: 24 }}
        align="center"
        justify="center"
      >
        <Col style={{ fontSize: 24 }} align="center" justify="center">
          {sensitivityLevels[sensitivityLevelsIndex]}
        </Col>
        <Col style={{ fontSize: 16 }}>{t('consultations.exercises.dbhl')}</Col>
      </Col>
      <Col
        style={{ minHeight: '30px', fontSize: 18 }}
        align="center"
        justify="center"
      >
        {handleMaxLimit()}
      </Col>
      <Col>
        <Button type="ghost" onClick={() => handleButtons('down')}>
          <DownCircleOutlined />
        </Button>
      </Col>
    </Row>
  );
};

PureToneAudiometrySensitivityButtons.propTypes = {
  sensitivityLevels: PropTypes.arrayOf(PropTypes.number),
  sensitivityLevelsIndex: PropTypes.number,
  setSensitivityLevelsIndex: PropTypes.func,
  mainChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  stepChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  setMainChartData: PropTypes.func,
  setStepChartData: PropTypes.func,
  activePointIndex: PropTypes.number,
  audioToPlay: PropTypes.shape({
    play: PropTypes.func
  }),
  dbLevel: PropTypes.number.isRequired,
  setMainPointCoordinates: PropTypes.func
};

PureToneAudiometrySensitivityButtons.defaultProps = {
  sensitivityLevels: null,
  sensitivityLevelsIndex: 10,
  setSensitivityLevelsIndex: null,
  mainChartData: null,
  setMainChartData: null,
  stepChartData: null,
  setStepChartData: null,
  activePointIndex: undefined,
  audioToPlay: null,
  setMainPointCoordinates: null
};
