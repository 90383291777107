import { Button, Form, Input, Row } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { TextArea } = Input;

export const EditMessage = ({
  consultation,
  forceRefresh,
  setForceRefresh
}) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const handleOk = async (values) => {
    const { content } = values;
    try {
      await dispatchAPI('POST', {
        url: 'messages',
        body: {
          content,
          recipient: consultation.specialist._id,
          consultation: consultation._id
        }
      });
      form.resetFields();
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      handleOk(values);
    } catch (e) {
      if (e.errorFields && e.errorFields.length)
        form.scrollToField(e.errorFields[0].name);
    }
  };

  return (
    <Form form={form}>
      <Form.Item name={['content']}>
        <TextArea />
      </Form.Item>
      <Row justify="end">
        <Form.Item>
          <Button
            htmlType="submit"
            type="add"
            shape="circle"
            onClick={handleSubmit}
          >
            <SendOutlined />
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

EditMessage.propTypes = {
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    specialist: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

EditMessage.defaultProps = {
  forceRefresh: undefined,
  setForceRefresh: null
};
