export const frequencies = ['DAILY', 'WEEKLY', 'MONTHLY', 'ANNUAL'];

export const useDaysOptions = (t) => [
  {
    label: t('unavailabilities.form.monday'),
    value: 1
  },
  {
    label: t('unavailabilities.form.tuesday'),
    value: 2
  },
  {
    label: t('unavailabilities.form.wednesday'),
    value: 3
  },
  {
    label: t('unavailabilities.form.thursday'),
    value: 4
  },
  {
    label: t('unavailabilities.form.friday'),
    value: 5
  },
  {
    label: t('unavailabilities.form.saturday'),
    value: 6
  }
];

export const useCountOptions = (t) => [
  {
    label: t('unavailabilities.form.first'),
    value: 1
  },
  {
    label: t('unavailabilities.form.second'),
    value: 2
  },
  {
    label: t('unavailabilities.form.third'),
    value: 3
  },
  {
    label: t('unavailabilities.form.fourth'),
    value: 4
  }
];

export const useMonthOptions = (t) => [
  {
    label: t('unavailabilities.form.january'),
    value: 1
  },
  {
    label: t('unavailabilities.form.february'),
    value: 2
  },
  {
    label: t('unavailabilities.form.march'),
    value: 3
  },
  {
    label: t('unavailabilities.form.april'),
    value: 4
  },
  {
    label: t('unavailabilities.form.may'),
    value: 5
  },
  {
    label: t('unavailabilities.form.june'),
    value: 6
  },
  {
    label: t('unavailabilities.form.july'),
    value: 7
  },
  {
    label: t('unavailabilities.form.august'),
    value: 8
  },
  {
    label: t('unavailabilities.form.september'),
    value: 9
  },
  {
    label: t('unavailabilities.form.october'),
    value: 10
  },
  {
    label: t('unavailabilities.form.november'),
    value: 11
  },
  {
    label: t('unavailabilities.form.december'),
    value: 12
  }
];
