import React from 'react';
import {
  CalendarOutlined,
  FileTextOutlined,
  UserOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <CalendarOutlined />,
  PATIENTS: <UserOutlined />,
  CONSULTATIONS: <FileTextOutlined />,
  PRESCRIPTIONS: <FileTextOutlined />,
  USERS: <UserOutlined />
};

export default navMenuLogos;
