import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import {
  consultationStatus,
  consultationTypes
} from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [specialists, setSpecialists] = useState([]);

  const getSpecialists = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=specialist:SPECIALIST`
      });
      setSpecialists(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSpecialists();
    })();
  }, []);

  return [
    {
      title: t('consultations.form.first_name'),
      key: 'patient',
      dataIndex: 'patient',
      render: (patient) => (patient && patient.first_name) || '-',
      sorter: (a, b) =>
        a?.patient?.first_name.localeCompare(b?.patient?.first_name)
    },
    {
      title: t('consultations.form.last_name'),
      key: 'patient',
      dataIndex: 'patient',
      render: (patient) => (patient && patient.last_name) || '-',
      sorter: (a, b) =>
        a?.patient?.last_name.localeCompare(b?.patient?.last_name)
    },
    {
      title: t('consultations.form.consultation_type'),
      key: 'immediate_consultation',
      dataIndex: 'immediate_consultation',
      render: (boolean) =>
        (
          <Tag
            color={
              boolean === true
                ? consultationTypes.IMMEDIATE
                : consultationTypes.BOOKED
            }
          >
            {boolean === true
              ? t('consultations.form.IMMEDIATE')
              : t('consultations.form.BOOKED')}
          </Tag>
        ) || '-',
      filters: Object.keys(consultationTypes).map((type) => ({
        text: t(`consultations.tags.${type}`),
        value: type === 'IMMEDIATE'
      }))
    },
    {
      title: t('consultations.form.audioprothesist'),
      key: 'specialist',
      dataIndex: 'specialist',
      render: (specialist) =>
        `${specialist?.last_name || ''} ${specialist?.first_name || ''}` || '-',
      sorter: (a, b) =>
        a?.specialist?.last_name.localeCompare(b?.specialist?.last_name),
      filters: specialists.map((specialist) => ({
        text: `${specialist?.last_name || ''} ${specialist?.first_name || ''}`,
        value: specialist._id
      }))
    },
    {
      title: t('consultations.form.array_date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => moment(date).format('DD/MM/YYYY à HH:mm') || '-',
      sorter: (a, b) =>
        moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD')
    },
    {
      title: t('consultations.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        (
          <Tag color={consultationStatus[status]}>
            {t(`consultations.form.${status}`)}
          </Tag>
        ) || '-',
      sorter: (a, b) => a?.status.localeCompare(b?.status)
    }
  ];
};
