export const useDocuments = (consultation, t) => [
  {
    title: t('consultations.files.prescription'),
    file: consultation.prescription,
    type: 'prescription'
  },
  {
    title: t('consultations.files.otoscopy'),
    file: consultation.otoscopy,
    type: 'otoscopy'
  },
  {
    title: t('consultations.files.anamnese'),
    file: consultation.anamnese,
    type: 'anamnese'
  },
  {
    title: t('consultations.files.audiogram_actions'),
    file: consultation.audiogram_actions,
    type: 'audiogram_actions'
  },
  ...(consultation.extraDocuments || []).map((document) => ({
    title: t('consultations.files.extra-documents'),
    file: document,
    type: 'extraDocuments'
  }))
];
