import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import {
  consultationStatus,
  consultationTypes
} from '../../utils/constants/tagColors';
import { routes } from '../../utils/constants/doctorsRoutes';

const iconSize = 18;

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('users.show.consultations-columns.patient.first_name'),
      key: 'patient',
      dataIndex: ['patient'],
      render: (patient) =>
        (patient && patient.first_name) || t('errors.form.not_specified'),
      sorter: (a, b) =>
        a?.patient?.first_name.localeCompare(b?.patient?.first_name)
    },
    {
      title: t('users.show.consultations-columns.patient.last_name'),
      key: 'patient',
      dataIndex: ['patient'],
      render: (patient) =>
        (patient && patient.last_name) || t('errors.form.not_specified'),
      sorter: (a, b) =>
        a?.patient?.last_name.localeCompare(b?.patient?.last_name)
    },
    {
      title: t('users.show.consultations-columns.immediate_consultation'),
      key: 'immediate_consultation',
      dataIndex: ['immediate_consultation'],
      render: (boolean) =>
        (
          <Tag
            color={
              boolean === true
                ? consultationTypes.IMMEDIATE
                : consultationTypes.BOOKED
            }
          >
            {boolean === true
              ? t('consultations.form.IMMEDIATE')
              : t('consultations.form.BOOKED')}
          </Tag>
        ) || t('errors.form.not_specified'),
      sorter: (a, b) => a.immediate_consultation - b.immediate_consultation
    },

    {
      title: t('users.show.consultations-columns.specialist'),
      key: 'specialist',
      dataIndex: ['specialist'],
      render: (specialist) =>
        (specialist && `${specialist.first_name} ${specialist.last_name}`) ||
        t('errors.form.not_specified'),
      sorter: (a, b) =>
        a?.specialist?.last_name.localeCompare(b?.specialist?.last_name)
    },
    {
      title: t('users.show.consultations-columns.date'),
      key: 'date',
      dataIndex: ['date'],
      render: (date) =>
        (date && moment(date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified'),
      sorter: (a, b) =>
        moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD')
    },
    {
      title: t('users.show.consultations-columns.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        (
          <Tag color={consultationStatus[status]}>
            {t(`consultations.form.${status}`)}
          </Tag>
        ) || '-',
      sorter: (a, b) => a?.status.localeCompare(b?.status)
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.CONSULTATIONS}/show/${record?._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
      )
    }
  ];
};
