import moment from 'moment';

const listContent = (data) => {
  const { center, specialist, date } = data;

  return [
    {
      label: 'prescriptions.show.center',
      span: 1,
      content: center?.label || '-'
    },
    {
      label: 'prescriptions.show.specialist',
      span: 1,
      content: `${specialist?.first_name} ${specialist?.last_name}` || '-'
    },
    {
      label: 'prescriptions.show.date',
      span: 1,
      content: moment(date).format('DD/MM/YYYY à HH:mm') || '-'
    }
  ];
};

export default listContent;
