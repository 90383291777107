import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { PageHeaderCustom } from '../../../components';
import { WaitingRoom } from './components/WaitingRoom';
import { ConsultationsDetails } from './components/ConsultationsDetails';
import { MaterialTest } from '../VideoConferencing/components/MaterialTest';
import { ExtraPageHeaderCustomButtons } from './components/ExtraPageHeaderCustomButtons';
import { Teleconsultation } from '../VideoConferencing/Teleconsultation';
import { useSocketContext } from '../VideoConferencing/SocketContext';
import { CancelMotiveModal } from './components/CancelMotiveModal';

const ShowConsultation = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { screen, setScreen } = useSocketContext();

  const [isLoading, setIsLoading] = useState(false);
  const [consultation, setConsultation] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const getConsultation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/consultations/${id}?populate=patient,center,doctor,specialist,doctor.center,extraDocuments,anamnese,prescription,otoscopy,audiogram_actions`
      });
      setConsultation(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getConsultation();
    })();
  }, [getConsultation, forceRefresh]);

  const renderHeaderTitle = () => {
    switch (screen) {
      case 1:
        return t('consultations.show.waiting_room');
      case 2:
        return Object.keys(consultation).length > 0
          ? `${t('consultations.show.consultation')} ${
              consultation?.patient.first_name
            } ${consultation?.patient.last_name} ${
              `${moment(consultation?.date).format('DD/MM/YYYY')} ` ||
              t('consultations.show.consultation')
            }`
          : t('consultations.show.consultation');
      case 3:
        return t('consultations.show.test_material_screen');
      default:
        return t('consultations.show.consultations_details');
    }
  };

  const renderShowConsultation = () => {
    switch (screen) {
      case 1:
        return (
          <WaitingRoom
            consultation={consultation.date}
            screen={screen}
            setScreen={setScreen}
          />
        );
      case 2:
        return <Teleconsultation consultation={consultation} />;
      case 3:
        return (
          <MaterialTest
            screen={screen}
            setScreen={setScreen}
            purpose="CONSULTATION"
          />
        );
      default:
        return (
          <ConsultationsDetails
            consultation={consultation}
            isLoading={isLoading}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
        );
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={renderHeaderTitle()}
        extra={
          <ExtraPageHeaderCustomButtons
            consultation={consultation}
            screen={screen}
            setScreen={setScreen}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
        }
      />
      {renderShowConsultation()}
      <CancelMotiveModal
        id={consultation._id}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
    </>
  );
};

export default ShowConsultation;
