import { Button, Col, Row } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { handleMax, handleMin } from './utils/graphUtils';

export const PureToneAudiometryFrequencyButtons = ({
  frequencyLevels,
  frequencyLevelsIndex,
  setFrequencyLevelsIndex,
  mainChartData,
  setMainChartData,
  setSecondChartData,
  setMainPointCoordinates,
  pickedExercise,
  pickedPeripheral
}) => {
  const { t } = useTranslation();

  const handleMinLimit = () => {
    if (frequencyLevelsIndex === 0) return t('consultations.exercises.min');
    return frequencyLevels[frequencyLevelsIndex - 1].replacementValue;
  };

  const handleMaxLimit = () => {
    if (frequencyLevelsIndex === frequencyLevels.length - 1)
      return t('consultations.exercises.max');
    return frequencyLevels[frequencyLevelsIndex + 1].replacementValue;
  };

  const handleButtons = (direction) => {
    const activePoint = mainChartData.datasets[0].data[0];
    let newPoint;
    const nextIndex = (frequencyLevelsIndex + 1) % frequencyLevels.length;
    const previousIndex =
      (frequencyLevelsIndex - 1 + frequencyLevels.length) %
      frequencyLevels.length;

    if (direction === 'left') {
      newPoint = {
        x: handleMin(
          frequencyLevels,
          frequencyLevelsIndex,
          'frequency',
          setFrequencyLevelsIndex,
          setMainPointCoordinates,
          'main'
        ),
        y: activePoint.y
      };

      setSecondChartData((prevChartData) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: frequencyLevels[previousIndex].exerciseDatasets[
              pickedExercise
            ][pickedPeripheral].scatterDataset
          },
          {
            ...prevChartData.datasets[1],
            data: frequencyLevels[previousIndex].exerciseDatasets[
              pickedExercise
            ][pickedPeripheral].lineDataset
          }
        ]
      }));
    } else {
      newPoint = {
        x: handleMax(
          frequencyLevels,
          frequencyLevelsIndex,
          'frequency',
          setFrequencyLevelsIndex,
          setMainPointCoordinates,
          'main'
        ),
        y: activePoint.y
      };

      setSecondChartData((prevChartData) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: frequencyLevels[nextIndex].exerciseDatasets[pickedExercise][
              pickedPeripheral
            ].scatterDataset
          },
          {
            ...prevChartData.datasets[1],
            data: frequencyLevels[nextIndex].exerciseDatasets[pickedExercise][
              pickedPeripheral
            ].lineDataset
          }
        ]
      }));
    }

    return setMainChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [newPoint]
        },
        ...prevChartData.datasets.slice(1)
      ]
    }));
  };

  return (
    <Row
      style={{
        width: '40%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Col>
        <Button type="ghost" onClick={() => handleButtons('left')}>
          <LeftCircleOutlined />
        </Button>
      </Col>
      <Col style={{ minHeight: '30px', fontSize: 18 }}>{handleMinLimit()}</Col>
      <Col style={{ minHeight: '30px' }} align="center" justify="center">
        <Col style={{ fontSize: 24 }}>
          {frequencyLevels[frequencyLevelsIndex].replacementValue}
        </Col>
        <Col style={{ fontSize: 16 }}>{t('consultations.exercises.hz')}</Col>
      </Col>
      <Col style={{ minHeight: '30px', fontSize: 18 }}>{handleMaxLimit()}</Col>
      <Col>
        <Button type="ghost" onClick={() => handleButtons('right')}>
          <RightCircleOutlined />
        </Button>
      </Col>
    </Row>
  );
};

const datasetPropTypes = PropTypes.shape({
  scatterDataset: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
  ).isRequired,
  lineDataset: PropTypes.arrayOf(
    PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
  ),
  threshold: PropTypes.number
});

const exerciseDatasetsPropTypes = PropTypes.shape({
  earphones: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  }),
  audioField: PropTypes.shape({
    withHearingAid: datasetPropTypes.isRequired,
    withoutHearingAid: datasetPropTypes.isRequired
  }),
  transducer: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  })
});

PureToneAudiometryFrequencyButtons.propTypes = {
  frequencyLevels: PropTypes.arrayOf(
    PropTypes.shape({
      replacementValue: PropTypes.number,
      tickValue: PropTypes.number,
      exerciseDatasets: PropTypes.shape({
        threshold: exerciseDatasetsPropTypes,
        UCL: PropTypes.shape({
          leftEar: datasetPropTypes.isRequired,
          rightEar: datasetPropTypes.isRequired
        })
      })
    })
  ),
  frequencyLevelsIndex: PropTypes.number,
  setFrequencyLevelsIndex: PropTypes.func,
  mainChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  setMainChartData: PropTypes.func,
  setMainPointCoordinates: PropTypes.func,
  setSecondChartData: PropTypes.func,
  pickedExercise: PropTypes.string,
  pickedPeripheral: PropTypes.string
};

PureToneAudiometryFrequencyButtons.defaultProps = {
  frequencyLevels: null,
  frequencyLevelsIndex: 10,
  setFrequencyLevelsIndex: null,
  mainChartData: null,
  setMainChartData: null,
  setMainPointCoordinates: null,
  setSecondChartData: null,
  pickedExercise: 'threshold',
  pickedPeripheral: 'earphones'
};
