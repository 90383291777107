import { Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  consultationStatus,
  consultationTypes
} from '../../utils/constants/tagColors';

const listContentPatient = (data = {}) => {
  const { t } = useTranslation();
  const {
    gender,
    first_name,
    last_name,
    patient_security_number,
    email,
    phone_number,
    date_of_birth,
    already_equipped
  } = data;

  return [
    {
      label: 'consultations.show.patient.gender',
      span: 1,
      content:
        (gender && t(`consultations.show.patient.${gender}`)) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.last_name',
      span: 1,
      content: (last_name && last_name) || t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.first_name',
      span: 1,
      content: (first_name && first_name) || t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.patient_security_number',
      span: 1,
      content:
        (patient_security_number && patient_security_number) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.email',
      span: 1,
      content:
        (email && <a href={`mailto:${email}`}>{email}</a>) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.phone_number_full',
      span: 1,
      content:
        phone_number && phone_number.number ? (
          <div>
            {` ${
              phone_number.country_code || '+33'
            } ${phone_number.number.slice(0, 1)} ${phone_number.number
              .slice(1)
              .replace(/(.{2})/g, '$1 ')}
                `}
          </div>
        ) : (
          t('errors.form.not_specified')
        )
    },
    {
      label: 'consultations.show.patient.date_of_birth',
      span: 1,
      content:
        (date_of_birth && moment(date_of_birth).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.patient.already_equipped',
      span: 1,
      content:
        (already_equipped && already_equipped
          ? t('consultations.show.patient.yes')
          : t('consultations.show.patient.no')) ||
        t('errors.form.not_specified')
    }
  ];
};

const listContentConsultation = (data = {}) => {
  const { immediate_consultation, specialist, date, center, status } = data;

  const { t } = useTranslation();

  return [
    {
      label: 'consultations.show.date',
      span: 1,
      content:
        (date && moment(date).format('DD/MM/YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.hour',
      span: 1,
      content:
        (date && moment(date).format('HH:mm')) || t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.center',
      span: 1,
      content: (center && `${center.label}`) || t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.audioprothesist',
      span: 3,
      content:
        (specialist && `${specialist.first_name} ${specialist?.last_name}`) ||
        t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.consultation_type',
      span: 2,
      content:
        (
          <Tag
            color={
              immediate_consultation === true
                ? consultationTypes.IMMEDIATE
                : consultationTypes.BOOKED
            }
          >
            {t(
              `consultations.show.${
                immediate_consultation === true ? 'IMMEDIATE' : 'BOOKED'
              }`
            )}
          </Tag>
        ) || t('errors.form.not_specified')
    },
    {
      label: 'consultations.show.consultation_status',
      span: 1,
      content:
        (status && (
          <Tag color={consultationStatus[status]}>
            {t(`consultations.show.${status}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    }
  ];
};

export { listContentConsultation, listContentPatient };
