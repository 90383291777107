import { Checkbox, Form, Row, Col, TimePicker } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const hourFormat = 'HH:mm';

export const UnavailabilityHourForm = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  return (
    <Col span={14}>
      <Row justify="end">
        <Form.Item
          name={['unavailability_hours', 'all_day']}
          label={t('unavailabilities.form.unavailability_hours.all_day')}
          valuePropName="checked"
        >
          <Checkbox onChange={() => setChecked(!checked)} />
        </Form.Item>
      </Row>
      {!checked && (
        <>
          <Row justify="end">
            <Form.Item
              name={['unavailability_hours', 'start_hour']}
              label={t('unavailabilities.form.unavailability_hours.start_hour')}
            >
              <TimePicker format={hourFormat} />
            </Form.Item>
          </Row>
          <Row justify="end">
            <Form.Item
              name={['unavailability_hours', 'end_hour']}
              label={t('unavailabilities.form.unavailability_hours.end_hour')}
            >
              <TimePicker format={hourFormat} />
            </Form.Item>
          </Row>
        </>
      )}
    </Col>
  );
};
