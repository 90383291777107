import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { PureToneAudiometryGraph } from './PureToneAudiometryGraph';
import { PureToneAudiometrySensitivityButtons } from './PureToneAudiometrySensitivityButtons';
import { PureToneAudiometryFrequencyButtons } from './PureToneAudiometryFrequencyButtons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/ErrorMessage';
import { StepGraph } from './StepGraph';
import { initialFrequencyLevelsState } from './utils/initialFrequencyLevelState';
import { PeripheralsManagementHeader } from './PeripheralsManagementHeader';
import { otoscopyAudios } from './utils/audiometryAudios';
import { initialMainChartDataState } from './utils/initialMainChartDataState';

const sensitivityLevels = [
  -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
  90, 95, 100, 105, 110, 115, 120
];

export const PureToneAudiometry = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  // CHART STATES
  const [sensitivityLevelsIndex, setSensitivityLevelsIndex] = useState(10);
  const [frequencyLevelsIndex, setFrequencyLevelsIndex] = useState(5);
  const [activePointIndex, setActivePointIndex] = useState(0);
  const [frequencyLevels, setFrequencyLevels] = useState([
    ...initialFrequencyLevelsState(sensitivityLevels, sensitivityLevelsIndex)
  ]);
  const [mainPointCoordinates, setMainPointCoordinates] = useState({
    x: frequencyLevels[frequencyLevelsIndex].tickValue,
    y: sensitivityLevels[sensitivityLevelsIndex]
  });

  // AUDIOS STATES
  const audios = otoscopyAudios();
  const [earSide, setEarSide] = useState('leftEar');
  const [pickedPeripheral, setPickedPeripheral] = useState('earphones');
  const [pickedExercise, setPickedExercise] = useState('threshold');
  const [hearingAid, setHearingAid] = useState(false);

  const audioToPlay = audios.find(
    (audio) =>
      audio.frequency === frequencyLevels[frequencyLevelsIndex].replacementValue
  ).audio;

  const saveOtoscopy = async () => {
    const body = {
      frequencies: [
        ...frequencyLevels.map((frequency) => ({
          exercise: {
            scatterDataset: frequency.exerciseDatasets.scatterDataset,
            lineDataset: frequency.exerciseDatasets.lineDataset,
            threshold: frequency.exerciseDatasets.threshold
          }
        }))
      ]
    };
    try {
      await dispatchAPI('POST', {
        url: 'otoscopies',
        body
      });
    } catch (e) {
      message(e);
    }
  };

  const [stepChartData, setStepChartData] = useState({
    datasets: [
      {
        data: [
          ...frequencyLevels[frequencyLevelsIndex].exerciseDatasets.threshold
            .earphones.leftEar.scatterDataset
        ],
        backgroundColor: 'red',
        pointRadius: 15,
        pointHoverRadius: 30,
        pointHitRadius: 15,
        pointBorderWidth: 2,
        pointHoverBackgroundColor: 'black',
        pointLabelFontSize: 14,
        type: 'scatter',
        datalabels: {
          align: 'center',
          anchor: 'center',
          color: 'white',
          formatter(_, context) {
            return context.chart.data.datasets[0].data[context.dataIndex].y;
          }
        }
      },
      {
        backgroundColor: 'transparent',
        borderColor: 'rgba(158, 148, 159, 0.39)',
        borderWidth: 2,
        pointRadius: 0,
        type: 'line',
        data: [
          ...frequencyLevels[frequencyLevelsIndex].exerciseDatasets.threshold
            .earphones.leftEar.lineDataset
        ],
        showLine: true,
        datalabels: null
      }
    ]
  });

  const [mainChartData, setMainChartData] = useState(
    initialMainChartDataState(mainPointCoordinates)
  );
  const [activeDatasetIndex, setActiveDatasetIndex] = useState(1);

  const pickDatasetIndex = () => {
    let index;
    switch (true) {
      case pickedPeripheral === 'earphones' && pickedExercise === 'threshold':
        index = earSide === 'leftEar' ? 1 : 2;
        break;
      case pickedPeripheral === 'transducer':
        index = earSide === 'leftEar' ? 3 : 4;
        break;
      case pickedPeripheral === 'audioField':
        index = hearingAid === true ? 5 : 6;
        break;
      case pickedPeripheral === 'earphones' && pickedExercise === 'UCL':
        index = earSide === 'leftEar' ? 7 : 8;
        break;
      default:
        index = 1;
    }

    return setActiveDatasetIndex(index);
  };

  useEffect(() => {
    pickDatasetIndex();
  }, [earSide, pickedPeripheral, frequencyLevelsIndex, hearingAid]);

  return (
    <>
      <PeripheralsManagementHeader
        setEarSide={setEarSide}
        earSide={earSide}
        setPickedPeripheral={setPickedPeripheral}
        pickedPeripheral={pickedPeripheral}
        hearingAid={hearingAid}
        setHearingAid={setHearingAid}
        pickedExercise={pickedExercise}
        setPickedExercise={setPickedExercise}
      />
      <Row>
        <Col span={24} align="center">
          <PureToneAudiometryFrequencyButtons
            activePointIndex={activePointIndex}
            setActivePointIndex={setActivePointIndex}
            frequencyLevels={frequencyLevels}
            frequencyLevelsIndex={frequencyLevelsIndex}
            setFrequencyLevelsIndex={setFrequencyLevelsIndex}
            mainChartData={mainChartData}
            setMainChartData={setMainChartData}
            setSecondChartData={setStepChartData}
            setMainPointCoordinates={setMainPointCoordinates}
            pickedExercise={pickedExercise}
            pickedPeripheral={pickedPeripheral}
          />
        </Col>
        <Col span={12}>
          <StepGraph
            sensitivityLevels={sensitivityLevels}
            sensitivityLevelsIndex={sensitivityLevelsIndex}
            setSensitivityLevelsIndex={setSensitivityLevelsIndex}
            stepChartData={stepChartData}
            setStepChartData={setStepChartData}
            mainChartData={mainChartData}
            setMainChartData={setMainChartData}
            frequencyLevels={frequencyLevels}
            setFrequencyLevels={setFrequencyLevels}
            setFrequencyLevelsIndex={setFrequencyLevelsIndex}
            frequencyLevelsIndex={frequencyLevelsIndex}
            earSide={earSide}
            pickedPeripheral={pickedPeripheral}
            mainPointCoordinates={mainPointCoordinates}
            activeDatasetIndex={activeDatasetIndex}
            pickedExercise={pickedExercise}
            hearingAid={hearingAid}
          />
        </Col>
        <Col span={12}>
          <Row>
            <Col style={{ flexDirection: 'column' }} span={20}>
              <Col style={{ display: 'flex', justifyContent: 'center' }} />
              <Col>
                <PureToneAudiometryGraph
                  activePointIndex={activePointIndex}
                  setActivePointIndex={setActivePointIndex}
                  sensitivityLevels={sensitivityLevels}
                  frequencyLevels={frequencyLevels}
                  frequencyLevelsIndex={frequencyLevelsIndex}
                  setFrequencyLevelsIndex={setFrequencyLevelsIndex}
                  sensitivityLevelsIndex={sensitivityLevelsIndex}
                  setSensitivityLevelsIndex={setSensitivityLevelsIndex}
                  chartData={mainChartData}
                  setChartData={setMainChartData}
                  saveOtoscopy={saveOtoscopy}
                  audioToPlay={audioToPlay}
                  dbLevel={sensitivityLevels[sensitivityLevelsIndex]}
                  mainPointCoordinates={mainPointCoordinates}
                  setMainPointCoordinates={setMainPointCoordinates}
                  pickedPeripheral={pickedPeripheral}
                  earSide={earSide}
                  activeDatasetIndex={activeDatasetIndex}
                  pickedExercise={pickedExercise}
                />
              </Col>
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PureToneAudiometrySensitivityButtons
                activePointIndex={activePointIndex}
                setActivePointIndex={setActivePointIndex}
                sensitivityLevels={sensitivityLevels}
                sensitivityLevelsIndex={sensitivityLevelsIndex}
                setSensitivityLevelsIndex={setSensitivityLevelsIndex}
                mainChartData={mainChartData}
                setMainChartData={setMainChartData}
                stepChartData={stepChartData}
                setStepChartData={setStepChartData}
                audioToPlay={audioToPlay}
                dbLevel={sensitivityLevels[sensitivityLevelsIndex]}
                setMainPointCoordinates={setMainPointCoordinates}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
