import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('patients.form.file_number'),
      key: 'file_number',
      dataIndex: 'file_number',
      sorter: (a, b) =>
        a.file_number && b.file_number
          ? a.file_number.localeCompare(b.file_number)
          : ''
    },
    {
      title: t('patients.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name)
    },
    {
      title: t('patients.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: t('patients.form.phone_number.number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: (a, b) =>
        a.phone_number.number.localeCompare(b.phone_number.number),
      render: (phone_number) =>
        `${phone_number?.country_code || ''} ${phone_number?.number || ''}` ||
        '-'
    },
    {
      title: t('patients.form.patient_security_number'),
      key: 'patient_security_number',
      dataIndex: 'patient_security_number',
      sorter: (a, b) =>
        a.patient_security_number.localeCompare(b.patient_security_number)
    }
  ];
};
