import { Button, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ControlOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSocketContext } from '../../VideoConferencing/SocketContext';
import background from '../../../../assets/images/waiting-room-screen.png';

export const WaitingRoom = ({ consultation }) => {
  const { t } = useTranslation();
  const { screen, setScreen, materialOK } = useSocketContext();
  const [timeDiff, setTimeDiff] = useState();

  useEffect(() => {
    const intervalTime = setInterval(() => {
      const now = moment();
      const diffInMinutes = moment(consultation).diff(now, 'minutes');
      setTimeDiff(diffInMinutes);
    }, 600);
    return () => clearInterval(intervalTime);
  }, [consultation]);

  return (
    <Row
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        flex: '4 1 auto',
        justifyContent: 'center'
      }}
    >
      {screen === 1 && (
        <Row className="waiting-room-card">
          {timeDiff && (
            <div>
              {timeDiff < 0 ? (
                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Row>
                    {t('consultations.show.consultation_should_have_begun')}
                  </Row>
                  <Row>{`${t('consultations.show.ago')} ${Math.abs(
                    timeDiff
                  )} ${t('consultations.show.minutes')}`}</Row>
                </Row>
              ) : (
                <>
                  <Row>{t('consultations.show.consultation_begins_in')}</Row>
                  <Row>{`${Math.abs(timeDiff)} ${t(
                    'consultations.show.minutes'
                  )}`}</Row>
                </>
              )}
            </div>
          )}
          <Button
            onClick={() => setScreen(3)}
            style={{
              backgroundColor: materialOK ? '#2DE180' : '#ff4d4f',
              color: '#FFFF'
            }}
          >
            <ControlOutlined />
            {t('consultations.show.run_test')}
          </Button>
        </Row>
      )}
    </Row>
  );
};

WaitingRoom.propTypes = {
  consultation: PropTypes.string
};

WaitingRoom.defaultProps = {
  consultation: undefined
};
