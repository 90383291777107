import React from 'react';
import moment from 'moment';

const userList = (data = {}) => {
  const {
    first_name,
    last_name,
    address,
    date_of_birth,
    email,
    phone_number,
    specialisation,
    RPPS_number,
    AM_number
  } = data;
  return [
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 2,
      content: first_name || '-'
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.specialisation',
      span: 1,
      content: specialisation || '-'
    },
    {
      label: 'users.form.RPPS_number',
      span: 1,
      content: RPPS_number || '-'
    },
    {
      label: 'users.form.AM_number',
      span: 1,
      content: AM_number || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code || '+33'} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.address_full',
      span: 3,
      content:
        address && address.number !== '' ? (
          <>
            {address.number} {address.street}
            <br />
            {address.additional}
            {address.additional && <br />}
            {address.postal_code} {address.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.date_of_birth',
      span: 1,
      content: date_of_birth ? moment(date_of_birth).format('ll') : '-'
    }
  ];
};

export default userList;
