export const initialFrequencyLevelsState = (
  sensitivityLevels,
  sensitivityLevelsIndex
) => [
  {
    replacementValue: 125,
    tickValue: 10,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 250,
    tickValue: 20,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 500,
    tickValue: 30,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 750,
    tickValue: 40,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 1000,
    tickValue: 50,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 1500,
    tickValue: 60,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 2000,
    tickValue: 70,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 3000,
    tickValue: 80,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 4000,
    tickValue: 90,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 6000,
    tickValue: 100,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  },
  {
    replacementValue: 8000,
    tickValue: 110,
    exerciseDatasets: {
      threshold: {
        earphones: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        audioField: {
          withoutHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          withHearingAid: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        },
        transducer: {
          leftEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          },
          rightEar: {
            scatterDataset: [
              {
                x: 1,
                y: sensitivityLevels[sensitivityLevelsIndex]
              }
            ],
            lineDataset: [],
            threshold: 0
          }
        }
      },
      UCL: {
        leftEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        },
        rightEar: {
          scatterDataset: [
            {
              x: 1,
              y: sensitivityLevels[sensitivityLevelsIndex]
            }
          ],
          lineDataset: [],
          UCL: 0
        }
      }
    }
  }
];
