import moment from 'moment';

export const unavailabilityCellstyleOptions = (
  date,
  doctorUnavailabilities
) => {
  for (let i = 0; i < doctorUnavailabilities?.length; i += 1) {
    if (
      moment(doctorUnavailabilities[i].date).format('DD-MM-YYYY') ===
        moment(date).format('DD-MM-YYYY') &&
      doctorUnavailabilities[i].allDay
    ) {
      return '#DADADA';
    }
  }
  return '#CAFFCC';
};
