import { Button, Col, Radio, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { svgFiles } from './utils/audiometryLogos';

const { Option } = Select;

export const PeripheralsManagementHeader = ({
  pickedPeripheral,
  setPickedPeripheral,
  setEarSide,
  hearingAid,
  setHearingAid,
  setPickedExercise
}) => {
  const { t } = useTranslation();

  return (
    <Row style={{ flexDirection: 'column', gap: 20 }}>
      <Row justify="end">
        <Select
          onChange={(value) => setPickedExercise(value)}
          placeholder={t('consultations.exercises.pure-tone-audiometry')}
        >
          <Option value="threshold">
            {t('consultations.exercises.threshold')}
          </Option>
          <Option value="UCL">UCL</Option>
        </Select>
        <Select
          onChange={(value) => setPickedExercise(value)}
          placeholder={t('consultations.exercises.vocal-audiometry')}
        >
          <Option value="silence">
            {t('consultations.exercises.silence')}
          </Option>
          <Option value="noise">{t('consultations.exercises.noise')}</Option>
          <Option value="adaptative-silence">
            {t('consultations.exercises.adaptative-silence')}
          </Option>
          <Option value="adaptative-noise">
            {t('consultations.exercises.adaptative-noise')}
          </Option>
        </Select>
      </Row>
      <Row justify="space-between">
        <Row style={{ flexDirection: 'column' }}>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 10
            }}
          >
            <Col>{t('consultations.exercises.ears')}</Col>
          </Row>
          <Row>
            <Col>
              <Button type="text" onClick={() => setEarSide('leftEar')}>
                <img src={svgFiles.LeftEar} alt="vibrator" />
              </Button>
              <Button type="text" onClick={() => setEarSide('rightEar')}>
                <img src={svgFiles.RightEar} alt="vibrator" />
              </Button>
            </Col>
          </Row>
        </Row>

        <Row>
          <Col>{t('consultations.exercises.hearing-aid')}</Col>
          <Col>
            <Radio.Group
              onChange={(e) => setHearingAid(e.target.value)}
              value={hearingAid}
              disabled={pickedPeripheral !== 'audioField'}
            >
              <Radio value>{t('consultations.exercises.yes')}</Radio>
              <Radio value={false}>{t('consultations.exercises.no')}</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'column' }}>
          <Row justify="center" align="middle">
            <Col>{t('consultations.exercises.transducers')}</Col>
          </Row>
          <Row>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <Col>
                <Button
                  onClick={() => setPickedPeripheral('earphones')}
                  type="text"
                >
                  <img
                    src={
                      pickedPeripheral === 'earphones'
                        ? svgFiles.ActivatedEarphones
                        : svgFiles.DeactivatedEarphones
                    }
                    style={{ width: '50px', height: '50px' }}
                    alt="earphones"
                  />
                </Button>
              </Col>
              <Col>{t('consultations.exercises.earphones')}</Col>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <Col>
                <Button
                  onClick={() => setPickedPeripheral('transducer')}
                  type="text"
                >
                  <img
                    src={
                      pickedPeripheral === 'transducer'
                        ? svgFiles.ActivatedTransducer
                        : svgFiles.DeactivatedTransducer
                    }
                    style={{ width: '50px', height: '50px' }}
                    alt="vibrator"
                  />
                </Button>
              </Col>
              <Col>{t('consultations.exercises.vibrator')}</Col>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <Col>
                <Button
                  onClick={() => setPickedPeripheral('audioField')}
                  type="text"
                >
                  <img
                    src={
                      pickedPeripheral === 'audioField'
                        ? svgFiles.ActivatedAudioField
                        : svgFiles.DeactivatedAudioField
                    }
                    style={{ width: '50px', height: '50px' }}
                    alt="audioField"
                  />
                </Button>
              </Col>
              <Col>{t('consultations.exercises.audioField')}</Col>
            </Col>
          </Row>
        </Row>
      </Row>
    </Row>
  );
};

PeripheralsManagementHeader.propTypes = {
  pickedPeripheral: PropTypes.string,
  setPickedPeripheral: PropTypes.func,
  setEarSide: PropTypes.func,
  hearingAid: PropTypes.bool,
  setHearingAid: PropTypes.func,
  setPickedExercise: PropTypes.func
};

PeripheralsManagementHeader.defaultProps = {
  pickedPeripheral: undefined,
  setPickedPeripheral: null,
  setEarSide: null,
  hearingAid: false,
  setHearingAid: null,
  setPickedExercise: null
};
