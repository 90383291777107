import React from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../contexts/AuthContext';

const headers = [
  {
    label: 'reference',
    key: 'reference'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'Phone number country code',
    key: 'phone_number.country_code'
  },
  {
    label: 'Phone number',
    key: 'phone_number.number'
  },
  {
    label: 'Address street number',
    key: 'address.number'
  },
  {
    label: 'Address street name',
    key: 'address.street'
  },
  {
    label: 'Address additional information',
    key: 'address.additional'
  },
  {
    label: 'Address postal code',
    key: 'address.postal_code'
  },
  {
    label: 'Address city',
    key: 'address.city'
  },
  {
    label: 'Address country',
    key: 'address.country'
  }
];

const ListPrescriptions = () => {
  const columns = useColumns();
  const { user } = useAuthContext();

  return (
    <ListResource
      resourceName="prescriptions"
      withCreateButton={false}
      columns={columns}
      headers={headers}
      resourceModelName="Prescription"
      extraQuery={`?doctor=${user._id}&populate=patient,doctor,specialist`}
    />
  );
};

export default ListPrescriptions;
