import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'antd';

export const PageHeaderCustom = ({ title, extra }) => (
  <PageHeader title={title} ghost={false} extra={extra} />
);

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element
};

PageHeaderCustom.defaultProps = {
  extra: null
};
