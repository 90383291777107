import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Popconfirm, Skeleton } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  DescriptionList,
  PageHeaderCustom,
  ContentCustom
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { listContentUnavailability } from './listContentUnavailability';

export const ShowUnavailability = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [unavailability, setUnavailability] = useState();

  const getUnavailability = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `unavailabilities/${id}`
      });
      setUnavailability(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const deleteUnavailability = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/unavailabilities/${id}`
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getUnavailability();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('unavailabilities.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.UNAVAILABILITIES}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUnavailability}
              icon={<WarningOutlined />}
            >
              <Button danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Card title={t('unavailabilities.show.infos')}>
            <DescriptionList
              data={listContentUnavailability(unavailability || {}, t)}
              translate
            />
          </Card>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
