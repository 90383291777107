import { Route, Routes } from 'react-router-dom';
import { Unavailabilities } from './Unavailabilities';
import { CreateUpdateUnavailability } from './CreateUpdateUnavailability';
import { ShowUnavailability } from './ShowUnavailability';

export const UnavailabilityRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateUnavailability purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateUnavailability purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowUnavailability />} />
    <Route index element={<Unavailabilities />} />
  </Routes>
);
