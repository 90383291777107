import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../components';
import { CustomHeader } from './CustomHeader';
import { Calendar } from '../../components/Calendar/Calendar';
import {
  monthCellRender,
  weekCellRender
} from '../../components/Calendar/CellRenders.tsx';
import { useHomeContext } from '../../contexts/HomeContext';

export const Home = () => {
  const { forceRefresh, searchFilter } = useHomeContext();
  const { t } = useTranslation();

  const extraQuery = `${searchFilter ? `keywords=${searchFilter}` : ''}`;

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <CustomHeader />
      <Calendar
        modes={['week', 'month']}
        resourceName="consultations"
        customRoute="custom-calendar"
        monthCellRender={monthCellRender}
        weekCellRender={weekCellRender}
        extraQuery={extraQuery}
        populate="patient,center"
        forceRefresh={forceRefresh}
        hourEnd={22}
        hourStart={9}
        noAllDayRow
        initialMode="month"
        dayRange="withSaturday"
      />
    </>
  );
};
