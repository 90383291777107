import { Col, Row, Select } from 'antd';
import { SwitchModes } from '../SwitchModes.tsx';

export const monthAnnualHeader = (
  { value, onChange },
  extraHeader,
  modes,
  mode,
  setMode
) => {
  const selectStart = 0;
  const selectEnd = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let index = selectStart; index < selectEnd; index += 1) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    );
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 2; i < year + 4; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ marginBottom: '12px' }}
    >
      <Row justify="space-between" align="middle" style={{ marginLeft: '8px' }}>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          className="my-year-select"
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
          value={String(year)}
        >
          {options}
        </Select>
        {mode === 'month' && (
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
            style={{ marginLeft: 8 }}
          >
            {monthOptions}
          </Select>
        )}
      </Row>
      <Row>
        <Col>
          <SwitchModes modes={modes} setMode={setMode} mode={mode} />
        </Col>
        <Col>{extraHeader}</Col>
      </Row>
    </Row>
  );
};
