import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('unavailabilities.form.start_date'),
      key: 'unavailability_range.start',
      dataIndex: 'unavailability_range',
      sorter: true,
      render: (record) =>
        (record && `${moment(record?.start).format('DD-MM-YYYY')}`) || '-'
    },
    {
      title: t('unavailabilities.form.end_date'),
      key: 'unavailability_range.end',
      dataIndex: 'unavailability_range',
      sorter: true,
      render: (record) =>
        record?.end
          ? `${moment(record?.end).format('DD-MM-YYYY')}`
          : t('unavailabilities.form.hasNoEnd')
    },
    {
      title: t('unavailabilities.form.recurrence'),
      key: 'date',
      dataIndex: 'frequency',
      render: (record) =>
        record && t(`unavailabilities.form.${record.periodicity}`)
    },
    {
      title: t('unavailabilities.form.start_hour'),
      key: 'date',
      dataIndex: 'unavailability_hours',
      render: (record) =>
        (record?.start_hour && `${moment(record?.start_hour).format('LT')}`) ||
        t('unavailabilities.form.all_day_low')
    },
    {
      title: t('unavailabilities.form.end_hour'),
      key: 'date',
      dataIndex: 'unavailability_hours',
      render: (record) =>
        (record?.end_hour && `${moment(record?.end_hour).format('LT')}`) ||
        t('unavailabilities.form.all_day_low')
    }
  ];
};
