/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { Input, Spin, Upload } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Dragger } = Upload;

export const SecuritySocialForm = ({ fileList, setFileList }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const allowedExtensions = ['jpg', 'png', 'pdf'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setFileList([file]);
        return false;
      }
      message('Pas un fichier PNG, JPG ou PDF.');
      return true;
    },
    fileList
  };

  const getSelectOptions = useCallback(() => {
    setIsFieldsLoading(true);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (() => {
      getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <Spin spinning={isFieldsLoading}>
      <legend> {t('patients.form.info_security')} </legend>
      <FormItem
        name="patient_security_number"
        label={t('patients.form.patient_security_number')}
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (value && value.length !== 15) {
                return Promise.reject(
                  new Error(t('patients.form.verification_security_number'))
                );
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="third_party_number"
        label={t('patients.form.third_party_number')}
        rules={[{ required: true }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="vital_card"
        label={t('patients.form.vital_card')}
        rules={[{ required: true }]}
      >
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      </FormItem>
    </Spin>
  );
};

SecuritySocialForm.propTypes = {
  fileList: PropTypes.arrayOf(),
  setFileList: PropTypes.func,
  isLoading: PropTypes.bool
};

SecuritySocialForm.defaultProps = {
  fileList: null,
  setFileList: null,
  isLoading: null
};
