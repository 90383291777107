/* eslint-disable no-underscore-dangle */

const imageUrls = [
  // ALL OF THESE URL ARE TESTING URLS, SYMBOL RESOURCES FROM CUSTOMER WILL BE PLACED HERE
  '',
  'https://freesvg.org/img/Happy-White-Cat.png',
  'https://w7.pngwing.com/pngs/802/825/png-transparent-redbubble-polite-cat-meme-funny-cat-meme.png'
];

export const symbolsDisplay = {
  id: 'symbolsDisplay',
  afterDraw(chart) {
    const ctx = chart.ctx;
    const scatterDatasets = chart.data.datasets.filter(
      (dataset) => dataset.type === 'scatter'
    );
    scatterDatasets.forEach((dataset, datasetIndex) => {
      if (dataset.pointStyle === 'image') {
        dataset.data.forEach((dataPoint, dataIndex) => {
          const img = new Image();
          img.src = imageUrls[datasetIndex];
          const meta = chart.getDatasetMeta(datasetIndex + 1);
          const point = meta.data[dataIndex];

          const x = point.x || 0;
          const y = point.y || 0;
          const radius = dataset.pointRadius || 0;

          ctx.drawImage(img, x - radius, y - radius, radius * 2, radius * 2);
        });
      }
    });
  }
};

export const hoverBackgroundColors = {
  id: 'hoverBackgroundColors',
  beforeDatasetDraw(chart) {
    const {
      ctx,
      tooltip,
      chartArea: { top, left, width },
      scales: { y }
    } = chart;

    if (tooltip && tooltip._active[0]) {
      const gridLineItems = y._gridLineItems;
      const pointYCoordinates = Math.trunc(tooltip._active[0].element.y);
      let newRectHeight;
      let topFunction = top;
      if (
        pointYCoordinates <= Math.trunc(gridLineItems[10].y1) &&
        pointYCoordinates >= Math.trunc(gridLineItems[12].y1)
      ) {
        newRectHeight = y._gridLineItems[9].y1 - y._gridLineItems[12].y1;
        ctx.fillStyle = 'rgb(218, 218, 218, 0.2)';
      } else if (
        pointYCoordinates <= Math.trunc(gridLineItems[8].y1) &&
        pointYCoordinates >= Math.trunc(gridLineItems[10].y1)
      ) {
        newRectHeight = y._gridLineItems[6].y1 - y._gridLineItems[8].y1;
        const heightDiff = y._gridLineItems[9].y1 - y._gridLineItems[12].y1;
        topFunction += heightDiff;
        ctx.fillStyle = 'rgb(49, 94, 242, 0.2)';
      } else if (
        pointYCoordinates <= Math.trunc(gridLineItems[5].y1) &&
        pointYCoordinates >= Math.trunc(gridLineItems[8].y1)
      ) {
        newRectHeight = y._gridLineItems[4].y1 - y._gridLineItems[7].y1;
        const heightDiff = y._gridLineItems[7].y1 - y._gridLineItems[12].y1;
        topFunction += heightDiff;
        ctx.fillStyle = 'rgb(32, 189, 12, 0.2)';
      } else if (
        pointYCoordinates <= Math.trunc(gridLineItems[3].y1) &&
        pointYCoordinates >= Math.trunc(gridLineItems[5].y1)
      ) {
        newRectHeight = y._gridLineItems[5].y1 - y._gridLineItems[7].y1;
        const heightDiff = y._gridLineItems[4].y1 - y._gridLineItems[12].y1;
        topFunction += heightDiff;
        ctx.fillStyle = 'rgb(255, 140, 0, 0.2)';
      } else {
        newRectHeight = y._gridLineItems[4].y1 - y._gridLineItems[7].y1;
        const heightDiff = y._gridLineItems[2].y1 - y._gridLineItems[12].y1;
        topFunction += heightDiff;
        ctx.fillStyle = 'rgb(221, 32, 8, 0.2)';
      }
      ctx.fillRect(left, topFunction, width, newRectHeight);
    }
  }
};

export const findBetweenValue = (values, value, direction) => {
  switch (direction) {
    case 'top':
      if (value === values[0]) return values[0];
      return values[values.indexOf(value) - 1] || null;
    case 'left':
      if (value === values[0].tickValue) {
        return values[0].tickValue;
      }
      return (
        values[values.findIndex((val) => val.tickValue === value) - 1]
          ?.tickValue || null
      );
    case 'bottom':
      if (value === values[values.length - 1]) return values[values.length - 1];
      return values[values.indexOf(value) + 1] || null;
    case 'right':
      if (value === values[values.length - 1].tickValue) {
        return values[values.length - 1].tickValue;
      }
      return (
        values[values.findIndex((val) => val.tickValue === value) + 1]
          ?.tickValue || null
      );
    default:
      return null;
  }
};

const createColoredLabel = (value) => {
  let color;
  if (value >= 0 && value <= 20) {
    color = 'grey';
  } else if (value > 20 && value <= 40) {
    color = '#315EF2';
  } else if (value > 40 && value <= 70) {
    color = '#20BD0C';
  } else if (value > 70 && value <= 90) {
    color = '#FF8C00';
  } else {
    color = '#DD2008';
  }
  return color;
};

export const stepGraphOptions = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: { enabled: false },
    datalabels: {
      anchor: 'center',
      align: 'center',
      offset: 0,
      font: {
        size: 14
      },
      display: (context) => context.datasetIndex === 0
    }
  },
  scales: {
    x: {
      type: 'linear',
      min: 0,
      max: 12,
      ticks: {
        stepSize: 1,
        autoSkip: false,
        maxRotation: 0,
        callback(value) {
          if (value === 0) {
            return 'Étapes';
          }
          return value;
        }
      },
      grid: {
        display: false
      },
      position: 'top'
    },
    y: {
      type: 'linear',
      min: -30,
      max: 130,
      ticks: {
        maxTicksLimit: 15,
        autoSkip: false,
        maxRotation: 0
      },
      grid: {
        display: false
      },
      display: false
    }
  }
};

export const chartOptions = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label(context) {
          const labels = [
            { replacementValue: 125, tickValue: 10 },
            { replacementValue: 250, tickValue: 20 },
            { replacementValue: 500, tickValue: 30 },
            { replacementValue: 750, tickValue: 40 },
            { replacementValue: 1000, tickValue: 50 },
            { replacementValue: 1500, tickValue: 60 },
            { replacementValue: 2000, tickValue: 70 },
            { replacementValue: 3000, tickValue: 80 },
            { replacementValue: 4000, tickValue: 90 },
            { replacementValue: 6000, tickValue: 100 },
            { replacementValue: 8000, tickValue: 110 }
          ];
          const formattedX = labels.find(
            (labelItem) => labelItem.tickValue === context.parsed.x
          ).replacementValue;
          const label = `${formattedX}, ${context.parsed.y}`;
          return label;
        }
      }
    }
  },
  scales: {
    x: {
      type: 'linear',
      min: 10,
      max: 110,
      ticks: {
        maxTicksLimit: 11,
        autoSkip: false,
        maxRotation: 0,
        callback(value) {
          const labels = [
            { replacementValue: 125, tickValue: 10 },
            { replacementValue: 250, tickValue: 20 },
            { replacementValue: 500, tickValue: 30 },
            { replacementValue: 750, tickValue: 40 },
            { replacementValue: 1000, tickValue: 50 },
            { replacementValue: 1500, tickValue: 60 },
            { replacementValue: 2000, tickValue: 70 },
            { replacementValue: 3000, tickValue: 80 },
            { replacementValue: 4000, tickValue: 90 },
            { replacementValue: 6000, tickValue: 100 },
            { replacementValue: 8000, tickValue: 110 }
          ];
          for (let i = 0; i < labels.length; i += 1) {
            if (labels[i].tickValue === value) {
              return labels[i].replacementValue;
            }
          }

          return null;
        }
      }
    },
    y: {
      type: 'linear',
      min: -10,
      max: 120,
      reverse: true,
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 15,
        callback(value) {
          const labels = [
            -5, 0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
            85, 90, 95, 100, 105, 110, 115, 120
          ];
          if (labels.includes(value)) return value;
          return null;
        },
        color: (par) => createColoredLabel(par.tick.value)
      }
    }
  }
};

export const handleMin = (
  values,
  index,
  purpose,
  setState,
  setMainPoint,
  location,
  pickedExercise
) => {
  if (index !== 0) {
    if (location === 'main' && purpose === 'frequency') {
      setMainPoint((prevMainPoint) => {
        const minimum = pickedExercise !== 'UCL' ? 0 : 30;
        const nextMainPointx =
          prevMainPoint.x - 10 < minimum ? minimum : prevMainPoint.x - 10;
        if (minimum !== nextMainPointx) {
          setState(index - 1);
        }
        return {
          ...prevMainPoint,
          x: nextMainPointx
        };
      });
    }
    if (location === 'main' && purpose === 'sensitivity') {
      setState(index - 1);
      setMainPoint((prevMainPoint) => {
        const nextMainPointy =
          prevMainPoint.y - 5 === -10 ? -10 : prevMainPoint.y - 5;
        return {
          ...prevMainPoint,
          y: nextMainPointy
        };
      });
    }
  } else if (index === 0) {
    setState(0);
  }
  if (purpose === 'frequency') {
    if (index === 0 && pickedExercise !== 'UCL') return values[0].tickValue;
    if (index === 2 && pickedExercise === 'UCL') return values[2].tickValue;
    return values[index - 1].tickValue;
  }
  if (index === 0) return values[0];

  return values[index - 1];
};

export const handleMax = (
  values,
  index,
  purpose,
  setState,
  setMainPoint,
  location,
  pickedExercise
) => {
  if (index !== values.length - 1) {
    if (location === 'main' && purpose === 'frequency') {
      setMainPoint((prevMainPoint) => {
        const maximum = pickedExercise !== 'UCL' ? 120 : 90;
        const nextMainPointx =
          prevMainPoint.x + 10 > maximum ? maximum : prevMainPoint.x + 10;
        if (maximum !== nextMainPointx) {
          setState(index + 1);
        }
        return {
          ...prevMainPoint,
          x: nextMainPointx
        };
      });
    }
    if (location === 'main' && purpose === 'sensitivity') {
      setState(index + 1);
      setMainPoint((prevMainPoint) => {
        const nextMainPointy =
          prevMainPoint.y + 5 === 120 ? 120 : prevMainPoint.y + 5;
        return {
          ...prevMainPoint,
          y: nextMainPointy
        };
      });
    }
  } else {
    setState(values.length - 1);
  }
  if (purpose === 'frequency') {
    if (pickedExercise !== 'UCL' && index === values.length - 1) {
      return values[values.length - 1].tickValue;
    }
    if (pickedExercise === 'UCL' && index === values.length - 3) {
      return values[values.length - 3].tickValue;
    }
    return values[index + 1].tickValue;
  }
  if (index === values.length - 1) return values[values.length - 1];
  return values[index + 1];
};
