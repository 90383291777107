import React, { useEffect, useRef } from 'react';
import { add, format, getDay, setDay, differenceInMinutes } from 'date-fns';
import { Table } from 'antd';

import {
  GenericEvent,
  CalendarBodyProps,
  EventsObject,
  EventBlockProps,
  ColumnNode
} from './types.tsx';
import { getDayHoursEvents, sizeEventBox, MIN_BOX_SIZE } from './utils.ts';
import { useTranslation } from 'react-i18next';

const BOX_POSITION_OFFSET = 26;
const TURQUOISE = '#36CFC9';
const ALL_DAY_ROW = 0;

const EventBlock = <T extends GenericEvent>({
  event,
  index,
  hour,
  events,
  onEventClick
}: EventBlockProps<T>) => {
  const getEventDay = getDay(new Date(event.endTime));
  const fitHourToDate = setDay(hour, getEventDay);

  const boxStyle = sizeEventBox(event, fitHourToDate);
  const boxLeftPosition = BOX_POSITION_OFFSET * index;

  return (
    <div
      style={{
        display:
          differenceInMinutes(new Date(event.endTime), fitHourToDate) === 0
            ? 'none'
            : 'block',
        height: boxStyle.boxSize + '%',
        zIndex: 1
      }}
      onClick={onEventClick ? () => onEventClick(event) : undefined}
      key={index}
    >
      <p>
        {event.title}
      </p>
    </div>
  );
};

function Calendar<T extends GenericEvent>({
  weekDates,
  getDayEvents,
  onEventClick,
  dayRange,
  hourStart,
  hourEnd,
  noAllDayRow
}: CalendarBodyProps<T>) {
  const { t } = useTranslation();
  const rowRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current?.scrollIntoView();
    }
  }, [rowRef]);

  const dayList = (dayRange: string | undefined) => {
    switch (dayRange) {
      case 'withSaturday':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      case 'weekends':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ];
      default:
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }
  };

  const dayColumns = dayList('withSaturday').map((day, counter) => {
    const columnDate = add(new Date(weekDates.startDate), {
      days: 1 + counter
    });
    const formattedDayandMonth =
      t(`calendar.days.${format(columnDate, 'iii')}`) +
      ' ' +
      format(columnDate, 'dd');
    return {
      title: formattedDayandMonth,
      dataIndex: day,
      key: day,
      width: 2,
      render: function (
        events: ColumnNode<T>,
        row: EventsObject<T>
      ): React.ReactNode | undefined {
        if (events && events.length > 0 && events instanceof Array) {
          const eventsBlock = events.map(function (
            event,
            index: number
          ): React.ReactNode {
            if (format(event.startTime, 'P') === format(columnDate, 'P')) {
              return (
                <EventBlock
                  key={event.eventId}
                  event={event}
                  index={index}
                  hour={row.hourObject}
                  events={events.length}
                  onEventClick={onEventClick}
                />
              );
            }
          });

          return {
            props: {
              style: { position: 'relative', padding: '0' }
            },
            children: <>{eventsBlock}</>
          };
        }
        return undefined;
      }
    };
  });
  const hourColumn = {
    title: t('calendar.hours.title'),
    dataIndex: 'hour',
    key: 'hour',
    width: 1,
    render: (hour: ColumnNode<T>, {}, id: number) => {
      return {
        props: {
          style: { width: '10%' }
        },
        children: <div>{hour}</div>
      };
    }
  };
  const tableColumns = [hourColumn, ...dayColumns];

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        dataSource={getDayHoursEvents(
          weekDates,
          getDayEvents,
          hourStart,
          hourEnd,
          noAllDayRow
        )}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        showHeader={true}
        onRow={(_, rowIndex) => {
          if (rowIndex === ALL_DAY_ROW && !noAllDayRow) {
            return {
              style: {
                backgroundColor: 'white',
                position: 'sticky',
                zIndex: 1,
                top: 0
              }
            };
          }
          return {};
        }}
        scroll={{
          y: 1000
        }}
        className="weekly-calendar"
      />
    </div>
  );
}

export default Calendar;
