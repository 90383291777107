import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { handleMax, handleMin, stepGraphOptions } from './utils/graphUtils';
import { StepGraphButtons } from './StepGraphButtons';

export const StepGraph = ({
  sensitivityLevels,
  sensitivityLevelsIndex,
  setSensitivityLevelsIndex,
  stepChartData,
  setStepChartData,
  setMainChartData,
  mainChartData,
  frequencyLevelsIndex,
  setFrequencyLevels,
  setFrequencyLevelsIndex,
  frequencyLevels,
  earSide,
  pickedPeripheral,
  activeDatasetIndex,
  pickedExercise,
  hearingAid
}) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();

  const [activePointIndex, setActivePointIndex] = useState(0);

  const [stepChartActiveDataSets, setStepChartActiveDatasets] = useState();

  const pickDataset = () => {
    const arrayIndex = frequencyLevels[frequencyLevelsIndex].exerciseDatasets;
    let dataset;
    switch (pickedPeripheral) {
      case 'earphones':
        switch (true) {
          case earSide === 'rightEar' && pickedExercise === 'threshold':
            dataset = arrayIndex.threshold.earphones.rightEar;
            break;
          case earSide === 'leftEar' && pickedExercise === 'UCL':
            dataset = arrayIndex.UCL.leftEar;

            break;
          case earSide === 'rightEar' && pickedExercise === 'UCL':
            dataset = arrayIndex.UCL.rightEar;
            break;
          default:
            dataset = arrayIndex.threshold.earphones.leftEar;
        }

        break;
      case 'transducer':
        dataset =
          earSide === 'leftEar'
            ? arrayIndex.threshold.transducer.leftEar
            : arrayIndex.threshold.transducer.rightEar;
        break;
      case 'audioField':
        dataset = hearingAid
          ? arrayIndex.threshold.audioField.withHearingAid
          : arrayIndex.threshold.audioField.withoutHearingAid;
        break;
      default:
        dataset = arrayIndex.threshold.earphones.leftEar;
    }
    setStepChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: dataset.scatterDataset
        },
        {
          ...prevChartData.datasets[1],
          data: dataset.lineDataset
        }
      ]
    }));
    setActivePointIndex(dataset.scatterDataset.length - 1);
    setStepChartActiveDatasets(dataset);
    return dataset;
  };

  useEffect(() => {
    pickDataset();
  }, [earSide, pickedPeripheral, frequencyLevelsIndex]);

  const nextIndex = () => {
    if (
      pickedExercise !== 'UCL' &&
      frequencyLevelsIndex === frequencyLevels.length - 1
    ) {
      return frequencyLevels.length - 1;
    }
    if (
      pickedExercise === 'UCL' &&
      frequencyLevelsIndex === frequencyLevels.length - 3
    ) {
      return frequencyLevels.length - 3;
    }
    return (frequencyLevelsIndex + 1) % frequencyLevels.length;
  };

  const previousIndex = () => {
    if (pickedExercise !== 'UCL' && frequencyLevelsIndex === 0) return 0;
    if (pickedExercise === 'UCL' && frequencyLevelsIndex === 2) return 2;
    return (
      (frequencyLevelsIndex - 1 + frequencyLevels.length) %
      frequencyLevels.length
    );
  };

  const isThreshold = !!stepChartActiveDataSets?.threshold;

  useEffect(() => {
    const chartCtx = chartRef.current.getContext('2d');
    const chartDataCopy = { ...stepChartData };
    const chart = new Chart(chartCtx, {
      type: 'line',
      data: {
        datasets: [
          {
            ...chartDataCopy.datasets[0],
            datalabels: {
              align: 'center',
              anchor: 'center',
              color: 'white',
              formatter(_, context) {
                return context.chart.data.datasets[0].data[context.dataIndex].y;
              }
            },
            pointBackgroundColor: (context) => {
              if (context.parsed) {
                const pointY = context.parsed.y;
                const lineDataSet = chartDataCopy?.datasets[1]?.data;

                if (lineDataSet?.length > 1) {
                  const lineY = chartDataCopy?.datasets[1]?.data[0].y;
                  if (lineY === pointY) return '#A4C187';
                }
              }
              return '#9A989A';
            },
            pointHoverBackgroundColor: (context) => {
              if (context.parsed) {
                const pointY = context.parsed.y;
                const lineDataSet = chartDataCopy.datasets[1].data;

                if (lineDataSet.length > 1) {
                  const lineY = chartDataCopy.datasets[1].data[0].y;
                  if (lineY === pointY) return '#A4C187';
                }
              }
              return 'black';
            }
          },
          {
            ...chartDataCopy.datasets[1]
          },
          {
            data: stepChartData.datasets[0].data,
            backgroundColor: 'transparent',
            borderColor: 'grey',
            borderCapStyle: 'butt',
            borderWidth: 2,
            type: 'line'
          }
        ]
      },
      options: stepGraphOptions,
      plugins: [ChartDataLabels]
    });
    chartRef.current.chartInstance = chart;

    const handleKeyDown = (event) => {
      const activePoint = stepChartData.datasets[0].data[activePointIndex];
      let newPoint;
      const dataSet = stepChartData.datasets[0].data;
      const dataSetLength = stepChartData.datasets[0].data.length;

      switch (event.code) {
        case 'ArrowUp':
          newPoint = {
            x: activePoint.x,
            y: handleMin(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              undefined,
              'step'
            )
          };
          break;
        case 'ArrowDown':
          newPoint = {
            x: activePoint.x,
            y: handleMax(
              sensitivityLevels,
              sensitivityLevelsIndex,
              'sensitivity',
              setSensitivityLevelsIndex,
              undefined,
              'step'
            )
          };
          break;
        case 'ArrowLeft':
          setActivePointIndex(
            stepChartActiveDataSets.scatterDataset.length - 1
          );
          setFrequencyLevelsIndex(previousIndex());
          return setStepChartData((prevChartData) => ({
            ...prevChartData,
            datasets: [
              {
                ...prevChartData.datasets[0],
                data: stepChartActiveDataSets.scatterDataset
              },
              {
                ...prevChartData.datasets[1],
                data: stepChartActiveDataSets.lineDataset
              }
            ]
          }));

        case 'ArrowRight':
          setActivePointIndex(
            stepChartActiveDataSets.scatterDataset.length - 1
          );
          setFrequencyLevelsIndex(nextIndex());
          return setStepChartData((prevChartData) => ({
            ...prevChartData,
            datasets: [
              {
                ...prevChartData.datasets[0],
                data: stepChartActiveDataSets.scatterDataset
              },
              {
                ...prevChartData.datasets[1],
                data: stepChartActiveDataSets.lineDataset
              }
            ]
          }));

        case 'Space':
          newPoint = {
            y: stepChartData.datasets[0].data[activePointIndex].y,
            x: dataSetLength + 1
          };
          if (dataSet.slice(0, -1).some((el) => el.y === newPoint.y)) {
            const lineData = [
              { x: 0, y: newPoint.y },
              { x: 12, y: newPoint.y }
            ];

            return setStepChartData((prevChartData) => ({
              ...prevChartData,
              datasets: [
                {
                  ...prevChartData.datasets[0]
                },
                {
                  ...prevChartData.datasets[1],
                  data: lineData
                }
              ]
            }));
          }

          setStepChartData((prevChartData) => ({
            ...prevChartData,
            datasets: [
              {
                ...prevChartData.datasets[0],
                data: [...prevChartData.datasets[0].data, newPoint]
              },
              { ...prevChartData.datasets[1] }
            ]
          }));
          return setActivePointIndex(activePointIndex + 1);
        case 'Enter':
          newPoint = {
            y: stepChartData.datasets[0].data[activePointIndex].y,
            x: dataSetLength + 1
          };

          return setFrequencyLevels((prevLevels) =>
            prevLevels.map((level, index) => {
              if (index === frequencyLevelsIndex) {
                const updatedPeripheral = {
                  ...level.exerciseDatasets[pickedExercise][pickedPeripheral]
                };
                switch (pickedPeripheral) {
                  case 'earphones':
                    switch (earSide) {
                      case 'leftEar':
                        updatedPeripheral.leftEar.scatterDataset =
                          stepChartData.datasets[0].data;
                        updatedPeripheral.leftEar.lineDataset =
                          stepChartData.datasets[1].data;
                        updatedPeripheral.leftEar.threshold = newPoint.y;
                        break;
                      case 'rightEar':
                        updatedPeripheral.rightEar.scatterDataset =
                          stepChartData.datasets[0].data;
                        updatedPeripheral.rightEar.lineDataset =
                          stepChartData.datasets[1].data;
                        updatedPeripheral.rightEar.threshold = newPoint.y;
                        break;
                      default:
                        break;
                    }
                    break;
                  case 'transducer':
                    switch (earSide) {
                      case 'leftEar':
                        updatedPeripheral.leftEar.scatterDataset =
                          stepChartData.datasets[0].data;
                        updatedPeripheral.leftEar.lineDataset =
                          stepChartData.datasets[1].data;
                        updatedPeripheral.leftEar.threshold = newPoint.y;
                        break;
                      case 'rightEar':
                        updatedPeripheral.rightEar.scatterDataset =
                          stepChartData.datasets[0].data;
                        updatedPeripheral.rightEar.lineDataset =
                          stepChartData.datasets[1].data;
                        updatedPeripheral.rightEar.threshold = newPoint.y;
                        break;
                      default:
                        break;
                    }
                    break;
                  default:
                    updatedPeripheral.scatterDataset =
                      stepChartData.datasets[0].data;
                    updatedPeripheral.lineDataset =
                      stepChartData.datasets[1].data;
                    updatedPeripheral.threshold = newPoint.y;
                    break;
                }

                return {
                  ...level,
                  exerciseDatasets: {
                    ...level.exerciseDatasets,
                    [pickedPeripheral]: updatedPeripheral
                  }
                };
              }
              return level;
            })
          );

        default:
          return null;
      }

      return setStepChartData((prevChartData) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: [
              ...prevChartData.datasets[0].data.slice(0, activePointIndex),
              newPoint,
              ...prevChartData.datasets[0].data.slice(activePointIndex + 1)
            ]
          },
          {
            ...prevChartData.datasets[1]
          }
        ]
      }));
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      chart.destroy();
    };
  }, [
    stepChartData,
    activePointIndex,
    stepChartActiveDataSets,
    frequencyLevelsIndex
  ]);

  return (
    <Row style={{ flexDirection: 'column' }}>
      <Col span={24}>
        <canvas ref={chartRef} />
      </Col>
      <Col>
        {isThreshold
          ? `${t('consultations.form.threshold')} ${
              stepChartActiveDataSets.threshold
            } ${t('consultations.form.dbhl')}`
          : null}
      </Col>
      <Col span={24}>
        <StepGraphButtons
          stepChartActiveDataSets={stepChartActiveDataSets}
          setStepChartData={setStepChartData}
          activePointIndex={activePointIndex}
          setMainChartData={setMainChartData}
          frequencyLevels={frequencyLevels}
          frequencyLevelsIndex={frequencyLevelsIndex}
          activeDatasetIndex={activeDatasetIndex}
          setActivePointIndex={setActivePointIndex}
          sensitivityLevels={sensitivityLevels}
          sensitivityLevelsIndex={sensitivityLevelsIndex}
          mainChartData={mainChartData}
          stepChartData={stepChartData}
        />
      </Col>
    </Row>
  );
};

const datasetPropTypes = PropTypes.shape({
  scatterDataset: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
  ).isRequired,
  lineDataset: PropTypes.arrayOf(
    PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
  ),
  threshold: PropTypes.number
});

const exerciseDatasetsPropTypes = PropTypes.shape({
  earphones: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  }),
  audioField: PropTypes.shape({
    withHearingAid: datasetPropTypes.isRequired,
    withoutHearingAid: datasetPropTypes.isRequired
  }),
  transducer: PropTypes.shape({
    leftEar: datasetPropTypes.isRequired,
    rightEar: datasetPropTypes.isRequired
  })
});

StepGraph.propTypes = {
  sensitivityLevels: PropTypes.arrayOf(PropTypes.number),
  sensitivityLevelsIndex: PropTypes.number,
  setSensitivityLevelsIndex: PropTypes.func,
  stepChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  frequencyLevels: PropTypes.arrayOf(
    PropTypes.shape({
      exerciseDatasets: PropTypes.shape({
        threshold: exerciseDatasetsPropTypes,
        UCL: PropTypes.shape({
          leftEar: datasetPropTypes.isRequired,
          rightEar: datasetPropTypes.isRequired
        })
      })
    })
  ),
  setStepChartData: PropTypes.func,
  frequencyLevelsIndex: PropTypes.number,
  setFrequencyLevels: PropTypes.func,
  setFrequencyLevelsIndex: PropTypes.func,
  earSide: PropTypes.string,
  pickedPeripheral: PropTypes.string,
  activeDatasetIndex: PropTypes.number,
  setMainChartData: PropTypes.func,
  mainChartData: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
        )
      })
    )
  }),
  pickedExercise: PropTypes.string,
  hearingAid: PropTypes.bool
};

StepGraph.defaultProps = {
  sensitivityLevels: null,
  sensitivityLevelsIndex: undefined,
  setSensitivityLevelsIndex: null,
  stepChartData: null,
  setStepChartData: null,
  frequencyLevelsIndex: undefined,
  frequencyLevels: [],
  setFrequencyLevels: null,
  setFrequencyLevelsIndex: PropTypes.func,
  earSide: 'leftEar',
  pickedPeripheral: 'earphones',
  activeDatasetIndex: 1,
  setMainChartData: null,
  mainChartData: null,
  pickedExercise: 'threshold',
  hearingAid: false
};
