import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdatePrescription = ({ purpose }) => {
  const type = 'all';
  const { fields, isFieldsLoading, base64 } = useFields(type);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && moment(data.date)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { photo: base64 } : {})
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="prescriptions"
      resource="prescriptions"
      config={config}
    />
  );
};

CreateUpdatePrescription.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePrescription;
