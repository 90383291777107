import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, message, Row } from 'antd';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { GeneralInfoForm } from './GeneralInfoForm';
import { SecuritySocialForm } from './SecuritySocialForm';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

const CreateUpdatePatient = ({ purpose }) => {
  const { dispatchAPI, patient, setPatient } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [fileList, setFileList] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === patient._id) setPatient({ ...patient, ...data });
        return {
          ...data
        };
      }
    }
  };

  const createPatient = async (values) => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      if (fileList.length) {
        fileList.forEach((file) => {
          formData.append('vital_card', file);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      await dispatchAPI('POST', {
        url: '/patients/',
        body: formData
      });

      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const updatePatient = async (values) => {
    try {
      setIsSubmitting(true);

      const formData = new FormData();

      if (fileList && fileList.length) {
        fileList.forEach((file) => {
          formData.append('vital_card', file);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      await dispatchAPI('PATCH', {
        url: `/patients/${id}/`,
        body: formData
      });

      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getPatient = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/patients/${id}/` });
      setPatientInfo(data);
      form.setFieldsValue({
        ...data,
        date_of_birth: moment(data.date_of_birth)
      });
    } catch (e) {
      message.error(e.message);
    }
    setIsLoading(false);
  }, [purpose, id, patientInfo]);

  useEffect(() => {
    if (purpose === 'edit') {
      setIsLoading(true);
      (async () => {
        await getPatient();
      })();
    }
  }, []);

  const handleSubmit = async (values) => {
    if (isLoading) {
      return;
    }

    setIsSubmitting(true);
    if (purpose === 'edit') {
      await updatePatient(values);
    } else if (purpose === 'create') {
      await createPatient(values);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageHeaderCustom title={t('patients.form.title.create')} />
      <Form
        {...formItemLayout}
        onFinish={handleSubmit}
        form={form}
        config={config}
        layout="vertical"
      >
        <ContentCustom>
          <GeneralInfoForm
            title={t('patients.form.title.necessary_information')}
          />
        </ContentCustom>
        <ContentCustom>
          <SecuritySocialForm
            fileList={fileList}
            setFileList={setFileList}
            title={t('patients.form.title.social_security_information')}
          />
          <Form.Item {...tailFormItemLayout}>
            <Row justify="end">
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={() => navigate(-1)}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit" loading={isSubmitting}>
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </ContentCustom>
      </Form>
    </>
  );
};

CreateUpdatePatient.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePatient;
