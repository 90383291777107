import { DatePicker, InputNumber, Radio, Form, Row, Col } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const dateFormat = 'DD-MM-YYYY';

export const UnavailabilityRangeForm = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);

  return (
    <Row gutter={[16]} className="unavailability-range">
      <Col span={10}>
        <Form.Item
          name={['unavailability_range', 'start']}
          label={t('unavailabilities.form.unavailability_range.start')}
        >
          <DatePicker format={dateFormat} />
        </Form.Item>
      </Col>
      <Col span={14}>
        <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
          <Radio value={1}>
            <Form.Item
              name={['unavailability_range', 'end']}
              label={t('unavailabilities.form.unavailability_range.end')}
            >
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Radio>
          <Radio value={2}>
            <Row>
              <Row>
                <Form.Item
                  name={['unavailability_range', 'end_after']}
                  label={t(
                    'unavailabilities.form.unavailability_range.end_after'
                  )}
                >
                  <InputNumber min={0} disabled={value !== 2} />
                </Form.Item>
              </Row>
              <Row>
                {t('unavailabilities.form.unavailability_range.occurence')}
              </Row>
            </Row>
          </Radio>
          <Form.Item
            name={['unavailability_range', 'has_no_end']}
            valuePropName="checked"
          >
            <Radio value={3}>
              {t('unavailabilities.form.unavailability_range.no_end_date')}
            </Radio>
          </Form.Item>
        </Radio.Group>
      </Col>
    </Row>
  );
};
