export const DbAudioOutput = async (dbLevel, audio) => {
  // Create an AudioContext instance
  const audioContext = new AudioContext();

  // Fetch the audio file
  const response = await fetch(audio.src);
  const arrayBuffer = await response.arrayBuffer();

  // Decode the audio data
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Create an AudioBufferSourceNode to play the audio
  const audioSource = audioContext.createBufferSource();
  audioSource.buffer = audioBuffer; // Set the audio buffer

  const gainNode = audioContext.createGain();

  // convert dbLevel to gain
  const gain = 10 ** (dbLevel / 20);

  gainNode.gain.setValueAtTime(gain, audioContext.currentTime);

  audioSource.connect(gainNode);
  gainNode.connect(audioContext.destination);

  audioSource.start();

  return audioSource;
};
