import React, { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { DoctorRouter } from './DoctorRouter';
import { useThemeContext } from '../contexts/ThemeContext';
import { DeveloperRouter } from './DeveloperRouter';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'developer:DEVELOPER':
        return <DeveloperRouter />;
      default:
        return <DoctorRouter />;
    }
  };
  return dispatchRouter();
};
