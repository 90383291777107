import { Button, Popover, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { routes } from '../../utils/constants/adminRoutes';

export const ConsultationManagementPopover = ({ location }) => {
  const { t } = useTranslation();

  const popoverContent = (
    <Row style={{ flexDirection: 'column' }}>
      <Row>
        <Link to={`${routes.UNAVAILABILITIES}/create`}>
          {t('home.form.add_unavailability')}
        </Link>
      </Row>
      <Row>
        <Link to={`${routes.UNAVAILABILITIES}`}>
          {t('home.form.unavailabilities_list')}
        </Link>
      </Row>
      <Row>
        {location === 'home' ? (
          <Link to={`${routes.CONSULTATIONS}`}>
            {t('home.form.consultations_list')}
          </Link>
        ) : (
          <Link to={`${routes.HOME}`}>{t('home.form.agenda')}</Link>
        )}
      </Row>
    </Row>
  );

  return (
    <Popover
      content={popoverContent}
      title={t('home.form.consultations_management')}
      trigger="click"
      placement="rightBottom"
    >
      <Button type="link">
        <MenuOutlined />
      </Button>
    </Popover>
  );
};

ConsultationManagementPopover.propTypes = {
  location: PropTypes.string.isRequired
};
