export const useDocuments = (user, t) => [
  { title: t('users.files.IDCard'), file: user.IDCard, type: 'IDCard' },
  {
    title: t('users.files.doctorDiplom'),
    file: user.doctorDiplom,
    type: 'doctorDiplom'
  },
  {
    title: t('users.files.otologyAttestation'),
    file: user.otologyAttestation,
    type: 'otologyAttestation'
  },
  {
    title: t('users.files.BAI'),
    file: user.BAI,
    type: 'BAI'
  },
  {
    title: t('users.files.signatureStamp'),
    file: user.signatureStamp,
    type: 'signatureStamp'
  },
  ...user.extraDocuments.map((document) => ({
    title: t('users.files.extra-documents'),
    file: document,
    type: 'extraDocuments'
  }))
];
