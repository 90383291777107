import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('prescriptions.form.consultation.patient'),
      key: 'consultation',
      dataIndex: 'consultation',
      render: (consultation) =>
        `${consultation?.patient?.last_name || ''} ${
          consultation?.patient?.first_name || ''
        }` || '-',
      sorter: true
    },
    {
      title: t('prescriptions.form.consultation.specialist'),
      key: 'specialist',
      dataIndex: 'specialist',
      render: (consultation) =>
        `${consultation?.specialist?.last_name || ''} ${
          consultation?.specialist?.first_name || ''
        }` || '-',
      sorter: true
    },
    {
      title: t('prescriptions.form.consultation.date'),
      key: 'consultation',
      dataIndex: 'consultation',
      render: (consultation) =>
        moment(consultation?.date).format('DD/MM/YYYY à HH:mm') || '-',
      sorter: true
    },
    {
      title: t('prescriptions.form.consultation.center'),
      key: 'consultation',
      dataIndex: 'consultation',
      render: (consultation) => consultation.center.label,
      sorter: true
    },
    {
      title: t('prescriptions.form.consultation.care_sheet'),
      key: 'care_sheet',
      dataIndex: 'care_sheet',
      render: (care_sheet) =>
        care_sheet ? (
          <CheckCircleTwoTone twoToneColor="#47d267" style={{ fontSize: 20 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 20 }} />
        ),
      sorter: true
    },
    {
      title: t('prescriptions.form.consultation.audio_graph'),
      key: 'audio_graph',
      dataIndex: 'audio_graph',
      render: (audio_graph) =>
        audio_graph ? (
          <CheckCircleTwoTone twoToneColor="#47d267" style={{ fontSize: 20 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 20 }} />
        ),
      sorter: true
    }
  ];
};
