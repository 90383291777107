import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSocketContext } from '../VideoConferencing/SocketContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom } from '../../../components';
import { EditMessage } from './EditMessage';

export const Messages = ({ consultation }) => {
  const { socket } = useSocketContext();
  const { dispatchAPI, user } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [messages, setMessages] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const getMessages = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `messages?consultation=${consultation._id}&populate=sender,recipient`
      });
      setMessages(data);
    } catch (e) {
      errorMessage(e);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('newMessage', async () => {
        await getMessages();
      });
    }
  }, [socket]);

  useEffect(() => {
    (async () => {
      await getMessages();
    })();
  }, [forceRefresh]);

  return (
    <ContentCustom>
      {messages.map((message) =>
        message?.sender?.role === user?.role ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                backgroundColor: '#69AEA7',
                borderRadius: '0px 15px 15px 15px',
                width: 'fit-content',
                padding: 15,
                margin: '7px 0px'
              }}
            >
              {message.content}
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div
              style={{
                borderRadius: '0px 15px 15px 15px',
                backgroundColor: '#E9F1F7',
                width: 'fit-content',
                padding: 15,
                margin: '7px 0px'
              }}
            >
              {message.content}
            </div>
          </div>
        )
      )}
      <EditMessage
        consultation={consultation}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
    </ContentCustom>
  );
};

Messages.propTypes = {
  consultation: PropTypes.shape({
    _id: PropTypes.string,
    doctor: PropTypes.shape({
      _id: PropTypes.string
    }),
    specialist: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired
};
