import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const endDetails = (data) => {
  const { t } = useTranslation();

  const endDetailsDisplay = () => {
    if (data?.end) {
      return moment(data?.end).format('L');
    }
    if (data?.end_after) {
      return `${t('unavailabilities.show.end_after')} ${data.end_after} ${t(
        'unavailabilities.show.occurences'
      )} `;
    }

    return t('unavailabilities.show.has_no_end');
  };

  return endDetailsDisplay();
};
