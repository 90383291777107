import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { recurrenceDetails } from './recurrenceDetails';
import { endDetails } from './endDetails';

export const listContentUnavailability = (data) => {
  const { t } = useTranslation();

  const { unavailability_range, frequency, unavailability_hours } = data;

  return [
    {
      label: 'unavailabilities.show.start',
      span: 1,
      content:
        (unavailability_range?.start &&
          moment(unavailability_range?.start).format('L')) ||
        '-'
    },
    {
      label: 'unavailabilities.show.end',
      span: 1,
      content: endDetails(unavailability_range) || '-'
    },
    {
      label: 'unavailabilities.show.recurrence',
      span: 1,
      content: t(`unavailabilities.show.${frequency?.periodicity}`) || '-'
    },
    {
      label: 'unavailabilities.show.recurrence_details',
      span: 1,
      content: recurrenceDetails(frequency) || '-'
    },
    {
      label: 'unavailabilities.show.start_hour',
      span: 1,
      content:
        (unavailability_hours?.start_hour &&
          moment(unavailability_hours?.start_hour).format('HH:mm')) ||
        t('unavailabilities.form.all_day_low')
    },
    {
      label: 'unavailabilities.show.end_hour',
      span: 1,
      content:
        (unavailability_hours?.end_hour &&
          moment(unavailability_hours?.end_hour).format('HH:mm')) ||
        t('unavailabilities.form.all_day_low')
    }
  ];
};
